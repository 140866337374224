import {
    Alert, AlertDescription, AlertIcon, AlertTitle,
    Box,
    Button,
    Center, CloseButton,
    Flex,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    useColorModeValue,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import FirstSlideForRepay from "./FirstSlideForRepay";
import SecondSlideForRepay from "./SecondSlideForRepay";
import ThirdSlideForRepay from "./ThirdSlideForRepay";
import { useSelector } from 'react-redux';
import algosdk, { bigIntToBytes, getApplicationAddress, decodeAddress, waitForConfirmation } from "algosdk";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update,
    Timestamp
} from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import axios from 'axios'
import { API_URL } from "../../url";
import { useDispatch } from "react-redux";
import { myAlgoWalletInfo, ResetStateAction } from "../redux/actions";
import { peraWallet } from "../../pages/WalletNew";
import { RepayAmountAction, RepayRemainingAmountAction, debtWithInterestAction } from '../redux/actions';
import { toast } from "react-toastify"
//import { PeraWalletConnect } from "@perawallet/connect";

//const peraWallet = new PeraWalletConnect();

function MainComponent() {
    const textColor = useColorModeValue("gray.600", "whiteAlpha.900");
    const [isFirstStepNextButtonDisabled, setIsFirstStepNextButtonDisabled] = useState(true);
    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    })
    const steps = [
        {
            label: "Select Active Loans",
            component: (props) => <FirstSlideForRepay {...props} setIsFirstStepNextButtonDisabled={setIsFirstStepNextButtonDisabled} setSelectedNFTs={setSelectedNFTs} setShowAlert={setShowAlert} />,
        },
        {
            label: "Enter Repay Amount",
            component: (props) => <SecondSlideForRepay {...props} />,
        },
        {
            label: "Approve Repayment",
            component: (props) => <ThirdSlideForRepay {...props} />,
        },
    ];
    //const steps = [{ label: "Select active loans", component: <FirstSlideForRepay /> }, { label: "Repay Amount", component: <SecondSlideForRepay /> }, { label: "Approve Gateaway", component: <ThirdSlideForRepay /> }]
    const [showAlert, setShowAlert] = useState(false);
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const checkbox = useSelector(state => state.token.repay_checkbox);
    const amountWithInterest = useSelector(state => state.token.debtWithInterest);
    const borrow_Amount = useSelector(state => state.token.nft_borrow_amount);
    //const borrowItemFromRedux = useSelector(state => state.token.borrow_item);
    const repayItemFromRedux = useSelector(state => state.token.repay_item);

    const asset_id = repayItemFromRedux.assetID;

    const borrow_amount = repayItemFromRedux.amount;
    //const nft_id = borrowItemFromRedux.nft_id;
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const pool_token = useSelector(state => state.token.borrow_app_id);
    const repay_amount = useSelector(state => state.token.repay_amount);
    const repay_remaining_amount = useSelector(state => state.token.repay_remaining_amount);
    const navigate = useNavigate();
    const [item2, setItem2] = useState(false);
    const modal2 = useDisclosure();
    const modal3 = useDisclosure();
    const modal4 = useDisclosure();
    const modal5 = useDisclosure();
    const [nftDetails, setNftDetails] = useState();
    const [nftForID, setID] = useState();
    const nftDepositRef = collection(db, "deposit");
    const nftBorrowRef = collection(db, "borrow");
    const [borrowDetails, setBorrowDetails] = useState();
    const [borrowRate, setBorrowRate] = useState();
    const [borrowAmount, setAmount] = useState();
    const [allBorrows, setAllBorrowNfts] = useState();
    const dispatchInput = useDispatch();
    const repayAmountFromRedux = useSelector(state => state.token.repay_amount);

    const handleResetState = () => {
        dispatchInput(ResetStateAction());
    };
    const handleRepay = () => {
        //console.log("======================repay function=====================");
        //assetOptin(asset_id);
        repay(asset_id);

    }
    //console.log('====== nft id=======')
    ////console.log(nft_id);
    //console.log(asset_id);
    const [nfts, setNfts] = useState([]);
    let totalDipositAmount = 0;
    let totalBorrowAmount = 0;
    useEffect(() => {
        handleResetState();
        if (asset_id) {
            const getNfts = async () => {

                const assetID = asset_id ? asset_id : 0;
                //const q2 = query(collection(db, "borrow"), where("assetID", "==", assetID));
                const q2 = query(collection(db, "borrow"), where("assetID", "array-contains", assetID[0]));
                //const q2 = query(collection(db, "borrow"), where("assetID", "in", assetID));
                //console.log("========hello, NFTs ========")
                const data2 = await getDocs(q2);
                //console.log("inside nft details", data2)


                data2.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let docData = doc.data();
                    //console.log("nftDetails data:=========", docData);
                    //console.log("nftDetails id:==========", doc.id);
                    await setNftDetails({ ...nftDetails, nftDetails: docData, nftId: doc.id })
                });
            };

            getNfts();

            const getNft = async () => {

                const assetID = asset_id ? asset_id : 0;
                //console.log("checking asset ID " + asset_id);
                const q2 = query(collection(db, "nfts"), where("assetID", "in", assetID));
                //console.log("========hello, i am here========")
                const data2 = await getDocs(q2);
                //console.log("inside nft details")

                data2.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let docData = doc.data();
                    //console.log("nftForID data: =======", docData);
                    //console.log("nftForID id: =====", doc.id);
                    await setID({ ...nftForID, nftForID: docData, nftId: doc.id })
                    //console.log("======Updated nftForID state: =======", nftForID);
                });
            };

            getNft();
        }

        const depositAmount = async () => {

            const data = await getDocs(nftDepositRef);
            ////console.log(data)
            data.docs.map((doc) => {
                totalDipositAmount += parseInt(doc.data().amount)
                ////console.log(totalDipositAmount);
                //dispatch(NFTtotalDepositAmount(totalDipositAmount));
                //setDiposit(totalDipositAmount);
            })
            // setDepositState(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
        };

        const borrowAmount = async () => {

            const data = await getDocs(nftBorrowRef);
            // //console.log(data);
            data.docs.map((doc) => {
                totalBorrowAmount += parseInt(doc.data().amount)
                // //console.log("total borrow amount" + totalBorrowAmount)
                setAmount(totalBorrowAmount);
                //dispatch(NFTtotalBorrowAmount(totalBorrowAmount));
            })
            // setDepositState(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
        };

        depositAmount();
        borrowAmount();

        const getNftRange = async () => {
            let rate = totalBorrowAmount / totalDipositAmount;
            //console.log(rate)
            let checkRate = Math.floor(rate);
            let checkRate2 = checkRate + 5;
            const q2 = query(collection(db, "range"), where("utilization_rate", ">", checkRate), where("utilization_rate", "<", checkRate2));

            const data2 = await getDocs(q2);
            //console.log("inside data")
            //console.log(data2)

            data2.forEach(async (doc) => {
                // doc.data() is never undefined for query doc snapshots
                let docData = doc.data();

                //console.log("inside data2")

                //console.log(doc)
                //   //console.log(doc.id, " => ", doc.data());
                await setBorrowDetails({ ...borrowDetails, borrowDetails: docData, nftId: doc.id })

            });

            setBorrowRate(data2.docs.map((doc) => ({ ...doc.data(), id: doc.borrow_rate })));

            // };

        }
        setTimeout(() => {
            getNftRange();
        }, 5000);

        const getAllBorrowNfts = async () => {

            const data = await getDocs(nftBorrowRef);
            setAllBorrowNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getAllBorrowNfts();

        return () => {
            handleResetState();
        };

    }, [repayItemFromRedux.assetID], [asset_id]);
    //console.log(allBorrows);

    /*
    useEffect(() => {
        //console.log("Updated nftDetails state:=====", nftDetails);
        console.log("Updated nftFoIDs state:=====", nftForID);
    }, [nftDetails, nftForID]);

     */


    // ====================finding excrow_app_address=================
    const borrowNft = allBorrows ? allBorrows.filter((data) => data.assetID === asset_id) : null;

    //console.log(borrowNft);
    let add = repayItemFromRedux?.escrow_app_id;

    // ====================finding excrow_app_address ends=================
    //console.log(borrowDetails);
    const borrow_rate = borrowDetails ? borrowDetails.borrowDetails.borrow_rate : 0;
    //console.log(borrow_rate);
    //console.log(borrowAmount);
    const borrow_apr = ((((borrowAmount + (borrowAmount * (borrow_rate / 100)) + ((borrowAmount * (borrow_rate / 100) * 0.3))) - borrowAmount) / borrowAmount) * 100).toFixed(2);
    //console.log(borrow_apr);

    /*
    const repay = async (asset_ids) => {

        if (!nftDetails || !nftDetails.nftId) {
            //console.log("nftDetails or nftDetails.nftId is not available yet.");
            return;
        }

        modal4.onOpen();
        const repay           = new Uint8Array(Buffer.from('repay', 'utf8'));
        const transfer_assets = new Uint8Array(Buffer.from('transfer_assets', 'utf8'));

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();
        let escrowAddress = "DAZ6VZKVQN6CIHLTCNEXI5K7YREWTMO6F32K2QZWILHBFBYHDSHTIC3BAQ";
        let borrowAmount = algosdk.algosToMicroalgos(repayAmountFromRedux);
        let creator = undefined;
        let apr = bigIntToBytes(borrow_apr, 8);
        let division = bigIntToBytes(1000000, 8);

        let finalamount_with_interest = algosdk.algosToMicroalgos(amountWithInterest);

        let amount_with_interest = bigIntToBytes((finalamount_with_interest), 8);
        let borrowNft = [];
        let multipleTxnGroupsDynamic = [];
        let groupTxns = [];

        const txn1 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            to: escrowAddress,
            amount: (borrowAmount)
        })


        const txn2 = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            appIndex: add,
            onComplete: 0,
            appArgs: [repay, apr, division, amount_with_interest],
            // foreignAssets: [asset_id],
            suggestedParams: suggested_params

        })
        multipleTxnGroupsDynamic.push({ txn: txn1, signers: [algoAdd] });
        multipleTxnGroupsDynamic.push({ txn: txn2, signers: [algoAdd] });
        groupTxns.push(txn1);
        groupTxns.push(txn2);

        if(repay_remaining_amount <= 0){
            asset_ids.forEach(element => {
                let assetType = 0;

                if(element == 10458941){
                    assetType = 1;
                }

                assetType       = bigIntToBytes(assetType, 8);
                let assetIdSend = bigIntToBytes(element,8);
                let amount      = bigIntToBytes(2000000,8);
                let tr_acc      = new Uint8Array(Buffer.from(algoAdd, 'utf8'));


                const txn3 = algosdk.makeApplicationCallTxnFromObject({
                    from: algoAdd,
                    appIndex: add,
                    onComplete: 0,
                    appArgs: [transfer_assets,assetType,assetIdSend,amount,tr_acc,amount_with_interest],
                    foreignAssets: [element],
                    suggestedParams: suggested_params
                })

                multipleTxnGroupsDynamic.push({
                    txn: txn3, signers: [algoAdd]
                });
                groupTxns.push(txn3);

            });


        }

        modal4.onClose();

        const groupID = algosdk.assignGroupID(groupTxns)

        //const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));
        const signedTxn = await peraWallet.signTransaction([multipleTxnGroupsDynamic]);

        const signedIndexArr = [];
        for (let index = 0; index < signedTxn.length; index++) {
            signedIndexArr.push(signedTxn[index])

        }
        const response = await algodClient.sendRawTransaction(signedIndexArr).do();

        // const response = await algodClient.sendRawTransaction([signedTxn[0], signedTxn[1]]).do();

        //console.log(response);
        let borrow = undefined;
        //console.log(repay_amount, repay_remaining_amount)
        if (repay_remaining_amount === 0) {
            borrow = false;
        } else {
            borrow = true
        }
        const userDoc = doc(db, "borrow", nftDetails?.nftId);
        await updateDoc(userDoc, { repay: true, repayAmount: repay_amount, repay_remaining_amount: repay_remaining_amount });

        const userDoc1 = doc(db, "nfts", nftForID?.nftId);
        await updateDoc(userDoc1, { repayAmount: repay_amount, repay_remaining_amount: repay_remaining_amount, borrow: borrow });
        //console.log(response);
        modal2.onOpen();
        setTimeout(() => {
            //setPopUp(false);
            modal2.onClose();
        }, 6000);
        setTimeout(() => {
            //setPopUp(false);
            navigate('/dashboard');
        }, 2000);
        setTimeout(() => {
            updateAccInfo();

        }, 7000);

    }
     */

    const repay = async (asset_ids) => {

        try {


            if (repay_remaining_amount < 0 || repay_remaining_amount > repay_amount) {
                modal5.onOpen();
                setTimeout(() => {
                    modal5.onClose();
                }, 3000);

            } else {
                //alert(repay_remaining_amount);
                modal4.onOpen();
                const repay = new Uint8Array(Buffer.from('repay', 'utf8'));
                const transfer_assets = new Uint8Array(Buffer.from('transfer_assets', 'utf8'));
                const algodClient = new algosdk.Algodv2("", 'https://node.testnet.algoexplorerapi.io', '');
                const suggested_params = await algodClient.getTransactionParams().do();
                let escrowAddress = getApplicationAddress(191913264);
                // console.log("==============escrowAddress============", escrowAddress); return 0;
                // let escrowAddress = "DAZ6VZKVQN6CIHLTCNEXI5K7YREWTMO6F32K2QZWILHBFBYHDSHTIC3BAQ";
                let borrowAmount = algosdk.algosToMicroalgos(repayAmountFromRedux);
                //console.log("==============Repay amount============", repayAmountFromRedux);
                let creator = undefined;
                let apr = bigIntToBytes(borrow_apr, 8);
                let division = bigIntToBytes(1000000, 8);
                let finalamount_with_interest = algosdk.algosToMicroalgos(amountWithInterest);
                let amount_with_interest = bigIntToBytes((finalamount_with_interest), 8);
                let borrowNft = [];
                let multipleTxnGroupsDynamic = [];
                let groupTxns = [];

                const txn1 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                    from: algoAdd,
                    suggestedParams: suggested_params,
                    to: escrowAddress,
                    amount: (borrowAmount)
                })

                const txn2 = algosdk.makeApplicationCallTxnFromObject({
                    from: algoAdd,
                    appIndex: add,
                    onComplete: 0,
                    appArgs: [repay, apr, division, amount_with_interest],
                    // foreignAssets: [asset_id],
                    suggestedParams: suggested_params
                })
                multipleTxnGroupsDynamic.push({ txn: txn1, signers: [algoAdd] });
                multipleTxnGroupsDynamic.push({ txn: txn2, signers: [algoAdd] });
                groupTxns.push(txn1);
                groupTxns.push(txn2);
                //console.log("=============", repay_remaining_amount);

                /*
                if(repay_remaining_amount <= 0){
                    asset_ids.forEach(element => {
                        let assetType = 0;
                        if(element == 10458941){
                            assetType = 1;
                        }
                        assetType       = bigIntToBytes(assetType, 8);
                        let assetIdSend = bigIntToBytes(element,8);
                        let amount      = bigIntToBytes(1,8);
                        let tr_acc      = new Uint8Array(Buffer.from(algoAdd, 'utf8'));
                        const txn3 = algosdk.makeApplicationCallTxnFromObject({
                            from: algoAdd,
                            appIndex: add,
                            onComplete: 0,
                            appArgs: [transfer_assets,assetType,assetIdSend,amount,tr_acc,amount_with_interest],
                            foreignAssets: [element],
                            suggestedParams: suggested_params
                        })
                        multipleTxnGroupsDynamic.push({
                            txn: txn3, signers: [algoAdd]
                        });
                        groupTxns.push(txn3);
                    });
                }
    
                 */

                if (repay_remaining_amount <= 0) {
                    asset_ids.forEach(element => {
                        let assetType = 0;
                        if (element == Number(process.env.REACT_APP_USDC_ID)) {
                            assetType = 1;
                        }
                        assetType = bigIntToBytes(assetType, 8);
                        let assetIdSend = bigIntToBytes(element, 8);

                        // Set the value of amount based on the condition
                        let amount = (element !== Number(process.env.REACT_APP_USDC_ID)) ? 1 : 0;
                        amount = bigIntToBytes(amount, 8);

                        let tr_acc = new Uint8Array(Buffer.from(algoAdd, 'utf8'));
                        const txn3 = algosdk.makeApplicationCallTxnFromObject({
                            from: algoAdd,
                            appIndex: add,
                            onComplete: 0,
                            appArgs: [transfer_assets, assetType, assetIdSend, amount, tr_acc, amount_with_interest],
                            foreignAssets: [element],
                            suggestedParams: suggested_params
                        })
                        multipleTxnGroupsDynamic.push({
                            txn: txn3, signers: [algoAdd]
                        });
                        groupTxns.push(txn3);
                    });
                }


                modal4.onClose();
                const groupID = algosdk.assignGroupID(groupTxns)
                //const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));
                const signedTxn = await peraWallet.signTransaction([multipleTxnGroupsDynamic]);
                const signedIndexArr = [];
                for (let index = 0; index < signedTxn.length; index++) {
                    signedIndexArr.push(signedTxn[index])
                }
                const response = await algodClient.sendRawTransaction(signedIndexArr).do();

                const currentTime = Date.now();
                // const response = await algodClient.sendRawTransaction([signedTxn[0], signedTxn[1]]).do();
                //console.log(response);
                let borrow = undefined;
                //console.log(repay_amount, repay_remaining_amount)
                if (repay_remaining_amount === 0) {
                    //alert(repay_remaining_amount)
                    borrow = false;
                } else {
                    //alert(repay_remaining_amount)
                    borrow = true
                }
                // alert(repay_remaining_amount)
                const userDoc = doc(db, "borrow", nftDetails.nftId);
                await updateDoc(userDoc, { repay: true, repayAmount: repay_amount, repay_remaining_amount: repay_remaining_amount });
                const userDoc1 = doc(db, "nfts", nftForID.nftId);
                await updateDoc(userDoc1, { repayAmount: repay_amount, repay_remaining_amount: repay_remaining_amount, borrow: borrow });
                //console.log(response);
                const userDocBorrowInfo = doc(db, "borrow_info_usdc", "w0UGGouZ9uaA8hWuLVRc");
                await updateDoc(userDocBorrowInfo, { change_in_time_new: currentTime });
                modal2.onOpen();
                setTimeout(() => {
                    //setPopUp(false);
                    modal2.onClose();
                }, 6000);
                setTimeout(() => {
                    //setPopUp(false);
                    navigate('/dashboard');
                }, 2000);
                setTimeout(() => {
                    updateAccInfo();
                }, 4000);
            }
        } catch (error) {
            toast.error("ERROR, in Repay.")
        }

    }

    const escrow_lsg_address = async () => {

        //  const escrow_address = getApplicationAddress(app_id)
        //  return escrow_address

        // let app_id = 93977532
        // let nft_id = nft_id
        let escrow_fund_program_compiled = undefined
        let escrow_address = undefined
        await axios(`${API_URL}swap/escrowLogicSig`)
            .then(response => {

                if (response.status === 200) {

                    let data = response.data;

                    escrow_fund_program_compiled = data.escrow_fund_program_compiled
                    escrow_address = data.escrow_address

                }


            })
            .catch(error => {
                console.error("Error fatching data", error);

            })

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');

        const escrowlcompiledProgram = await algodClient.compile(escrow_fund_program_compiled).do();



        const escrowProgramBytes = new Uint8Array(
            Buffer.from(escrowlcompiledProgram.result, 'base64')
        );

        //console.log(escrowProgramBytes);

        return escrowProgramBytes;


    }

    const updateAccInfo = async () => {
        let accInfo = {};
        await axios(`${API_URL}swap/accountInfo?address=` + algoAdd)
            .then(response => {

                if (response.status === 200) {
                    accInfo = response.data;
                    //console.log(accInfo);
                    const address = accInfo.clientInfo.address;
                    const amount = accInfo.clientInfo.amount / 1000000;
                    dispatchInput(myAlgoWalletInfo({
                        algoAddress: address,
                        algoBalance: amount
                    }));
                    //console.log(accInfo);
                    //console.log(amount);
                }

            })
            .catch(error => {
                console.error("Error fatching data", error);
            })
    }

    const resetReduxValue = () => {
        dispatchInput(RepayAmountAction(""));
        dispatchInput(RepayRemainingAmountAction(""))
    }

    return (
        <>
            <Flex flexDir="column" width="100%">
                <Steps activeStep={activeStep}>
                    {steps.map(({ label, component }, index) => (
                        <Step label={label} key={label}>
                            {component()}
                        </Step>
                    ))}
                </Steps>

                {activeStep === steps.length ? (
                    <Flex px={4} py={4} width="100%" flexDirection="column">
                        <Heading fontSize="xl" textAlign="center">
                            Woohoo! All steps completed!
                        </Heading>
                        <Text textAlign="center" fontWeight="bold">
                            Please click on the Repay button
                            to sign the transaction using your wallet!
                        </Text>
                        <Flex width="100%" justify="space-between" py="20px">
                            <Button
                                isDisabled={activeStep === 0}
                                mr={4}
                                size="md"
                                variant="ghost"
                                width="50%"
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                                onClick={reset}
                            >
                                Reset
                            </Button>
                            <Button
                                isDisabled={activeStep === 0}
                                mr={4}
                                size="md"
                                variant="ghost"
                                width="50%"
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                                onClick={handleRepay}
                            >
                                Repay
                            </Button>
                        </Flex>
                    </Flex>

                ) :

                    (
                        <Flex width="100%" justify="space-between">
                            <Button
                                isDisabled={activeStep === 0}
                                mr={4}
                                onClick={prevStep}
                                size="md"
                                variant="ghost"
                                width="50%"
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                            >
                                Prev
                            </Button>
                            <Button
                                isDisabled={activeStep === 0 && isFirstStepNextButtonDisabled}
                                size="md" onClick={activeStep === 0 && selectedNFTs.length < 1
                                    ? () => setShowAlert(true)
                                    : () => (activeStep === steps.length ? handleRepay : nextStep)()} width="50%" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}

                            >
                                {activeStep === steps.length - 1 ? "Next" : "Next"}
                            </Button>

                        </Flex>
                    )}
                {activeStep === 0 ? resetReduxValue() : ""}

            </Flex>

            {showAlert && (
                <Alert status="error" borderRadius="15px" mb="1em">
                    <AlertIcon />
                    <AlertTitle mr={2}>Error!</AlertTitle>
                    <AlertDescription>Please select at least one NFT.</AlertDescription>
                    <CloseButton position="absolute" right="8px" top="8px" onClick={() => setShowAlert(false)} />
                </Alert>
            )}
            {/* ==================successful Borrow offer modal start=================== */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="400px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Congratulations!!</Text>
                                <Text color={textColor}>You successfully repay the amount.</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================successful Borrow offer modal ends=================== */}

            <Modal isOpen={modal3.isOpen} onClose={modal3.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Oooppppsss!!</Text>
                                <Text color={textColor}>You didn't enter BORROW AMOUNT!!!</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* ==================Loader Modal====================== */}
            <Modal isOpen={modal4.isOpen} onClose={modal4.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Repaying NFT.....</ModalHeader>

                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================Loader Modal ends================= */}

            <Modal isOpen={modal5.isOpen} onClose={modal5.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>WARNING</ModalHeader> */}

                    <ModalBody alignItems="center" py="20px">
                        <VStack alignItems="center" justifyContent="space-evenly">
                            <Text color={textColor} style={{ fontSize: "25px", fontWeight: "bold", textAlign: "center" }}>opppppssss.....</Text>
                            <Text color={textColor} style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>Please check your Repay amount</Text>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MainComponent
