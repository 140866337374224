import {
    Box, Container, Input, Text, VStack, FormControl,
    FormLabel,
    FormHelperText,
    Select,
    Stack,
    HStack,
    Flex,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Divider,
    IconButton,
    Alert,
    AlertIcon,
    Spinner,
    Checkbox,
    RadioGroup,
    Radio,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import { CloseIcon, HamburgerIcon, SmallAddIcon } from '@chakra-ui/icons';
import MyAlgoConnect from "@randlabs/myalgo-connect";
import algosdk from 'algosdk';
import {
    collection,
    getDocs,
    addDoc
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { create } from "ipfs-http-client";
import { NFTStorage, File, Blob } from 'nft.storage';
import { useLocation } from "react-router-dom";
import Header from '../components/header/Header';
//import { PeraWalletConnect } from "@perawallet/connect";
import { peraWallet } from "./WalletNew";
import { toast } from "react-toastify"

//const peraWallet = new PeraWalletConnect();
//console.log("Pera Wallet connection ", peraWallet.isConnected, peraWallet.platform);

const client = create("https://ipfs.infura.io:5001/api/v0");

const imageMimeType = /image\/(png|jpg|jpeg|gif)/i;

function CreateItem(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const peraAdd = useSelector(state => state.token.perawallet_address);
    const [popUp, alertPopUp] = useState(false);
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [otherFile, setOtherFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [name, setName] = useState();
    const [priceNft, setPrice] = useState();
    const [priceCheck, setPriceCheck] = useState(false);
    const [link, setLink] = useState("");
    const [description, setDescription] = useState("");
    const [option, setOption] = useState("");
    const [blockchain, setblockchain] = useState("");
    const [inputFields, setInputFields] = useState([
        { firstName: "", lastName: "" },
    ]);
    const [assetId, setAssetID] = useState();
    const [AppId, setAppId] = useState();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal2 = useDisclosure();
    const [progress, setProgress] = useState(0);
    const [imageURL, setImageURL] = useState(null);
    const [check, setCheck] = useState(false);
    const [radioValue, setRadioValue] = useState();
    const [extension, setExtension] = useState();
    const nftCloseAuctionRef = collection(db, "closeAuction");

    const handleCheckBox = () => {
        setCheck(!check);

    }

    const handleChangeInput = (e, index) => {
        const values = [...inputFields];
        values[index][e.target.name] = e.target.value;
        setInputFields(values);
    }
    const handleSubmit = (e) => {
        e.preventDefault();

    }
    const handleAdd = () => {
        setInputFields([...inputFields, { firstName: "", lastName: "" }])
    }
    const handleRemove = (index) => {
        const length = inputFields.length;
        if (length > 1) {
            const values = [...inputFields];
            values.splice(index, 1);
            setInputFields(values);
        } else return null;
    }
    /*
    ** Storing image in local
    */
    const onImageChange = (e) => {
        const file = e.target.files[0];
        if (file?.name.length > 24) {
            alert("File name is too long. Please choose a file with a name less than 24 characters.");
            return;
        }
        if (!file?.type.match(imageMimeType)) {
            alert("Image type is not valid");
            return;
        }
        setFile(file);

    };

    /*
    ** Storing image or 3D file in local
    */
    const onOtherImageChange = (e) => {
        const file = e.target.files[0];
        if (file.name.length > 24) {
            alert("File name is too long. Please choose a file with a name less than 24 characters.");
            return;
        }
        const name = e.target.files[0]?.name;
        const ext = name?.split(".").pop();
        setExtension(ext);
        setOtherFile(file);

    };


    useEffect(() => {
        let fileReader, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    }, [file]);
    const nftCollectionRef = collection(db, "nfts");
    const [nfts, setNfts] = useState([]);
    const getNfts = async () => {
        const data = await getDocs(nftCollectionRef);
        setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    /*
    ** uploading file into IPFS
    */
    const uploadFile = async (e) => {
        e.preventDefault();
        if (priceNft > 0) {
            setOption(location.state ? location.state.data.category : option);
            const category_option = location.state ? location.state.data.category : option;

            //return 0;
            modal2.onOpen();
            try {
                const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGNGZDFlY2MxNDIxOWJERkNBQTRiRjdkRDVjODRCNmQyM2Y2MzlEYWIiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MDI0ODQzODgxOSwibmFtZSI6ImhpbWVsIn0.mMZhIVtiAO72iqDf8CfqfQ9JNyACUfuUzNXdo0yxkSg'
                const client = new NFTStorage({ token: NFT_STORAGE_TOKEN })
                const metadata = await client.store({
                    name: 'My',
                    description: 'J',
                    image: file
                })

                let metaDataUrl = metadata.data.image.href.replace('ipfs://', '')
                const firebase_url = `https://nftstorage.link/ipfs/${metaDataUrl}`;
                const asset_url = metadata.data.image.href;
                let firebase_url2 = undefined;
                let asset_url2 = undefined;

                if (check) {
                    const metadata2 = await client.store({
                        name: 'My something',
                        description: 'J',
                        image: otherFile
                    })
                    let metaDataUrl2 = metadata2.data.image.href.replace('ipfs://', '')
                    firebase_url2 = `https://nftstorage.link/ipfs/${metaDataUrl2}`;
                    asset_url2 = metadata2.data.image.href;

                    createNft(firebase_url, firebase_url2, asset_url, asset_url2);
                }
                else {
                    createNft(firebase_url, firebase_url2, asset_url, asset_url2, category_option);
                }
            } catch (error) {

            }
        } else {
            setPriceCheck(true);
            setTimeout(() => {
                setPriceCheck(false);
            }, 3000);
        }


    }

    const createNft = async (image_URL, other_file, asset_url, asset_url2, category_option) => {
        // e.preventDefault();

        try {
            const collection_type = location.state ? true : false;
            const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
            const suggested_params = await algodClient.getTransactionParams().do();
            const creator = algoAdd;
            const defaultFrozen = false;
            //const unitName = grpname;
            const assetName = name;
            const price = priceNft;
            const url = image_URL;
            const otherFile = check ? other_file : null;
            const fileExt = check ? extension : null;
            const assetAttribute = check ? radioValue : null;
            const managerAddr = algoAdd;
            const reserveAddr = algoAdd;
            const freezeAddr = algoAdd;
            const clawbackAddr = algoAdd;
            const total = 1;                // NFTs have totalIssuance of exactly 1
            const decimals = 0;             // NFTs have decimals of exactly 0
            // const asset = check ? otherFile : url;
            const asset = check ? asset_url2 : asset_url;
            const d = new Date();
            //console.log("Pera Wallet connection check 2 ", peraWallet.isConnected, peraWallet.platform);

            const txn = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
                from: creator,
                total,
                decimals,
                assetName,
                //unitName,
                assetURL: asset,
                assetMetadataHash: undefined,
                defaultFrozen,
                freeze: freezeAddr,
                manager: managerAddr,
                clawback: clawbackAddr,
                reserve: reserveAddr,
                suggestedParams: suggested_params,
            });
            modal2.onClose();

            const myAlgoConnect = new MyAlgoConnect();
            //const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());
            const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

            const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
            // console.log(signedTxn); return 0;
            const response = await algodClient.sendRawTransaction(signedTxn).do();
            modal2.onOpen();
            let assetID = null;
            // wait for transaction to be confirmed
            const ptx = await algosdk.waitForConfirmation(algodClient, response.txId, 4);
            assetID = ptx["asset-index"];

            setAssetID(assetID);
            //app-initializer function
            await addDoc(nftCollectionRef, { nft_id: assetID, name: assetName, price: price, url: url, other_file: otherFile, other_file_extension: fileExt, total: total, address: algoAdd, owner_address: algoAdd, option: category_option, assetID: assetID, description: description, link: link, type: "", sell_status: false, assetAttribute: assetAttribute, collection_type: collection_type });
            getNfts();

            addDoc(nftCloseAuctionRef, { owner_address: algoAdd, buyer_address: "", assetID: assetID, auctionType: 'created', price: price, date: d });

            alertPopUp(true);
            //console.log();
            modal2.onClose();
            setTimeout(() => {

                alertPopUp(false);
                navigate("/single", { state: { nft: true, name: assetName, price: price, url: url, total: total, address: algoAdd, option: option, assetID: assetID, description: description, link: link, type: "" } });
            }, 3000);
        } catch (error) {
            toast.error("Something went wrong !!!")
        }
    }




    return (
        <>
            <Header />
            <Box minH="100vh" pt="50px">
                <Container maxW={'4xl'} paddingTop="100px">
                    <VStack gap={2} align="left">
                        <Text fontSize="35px" fontWeight="bold">Create New Item</Text>
                        <Text fontSize="15px" ><span style={{ color: "red" }}>*</span> Required fields</Text>
                        <Text fontSize="15px" paddingBottom="20px">File types supported: JPG, PNG, JPEG
                        </Text>
                    </VStack>
                    {fileDataURL ?
                        <p className="img-preview-wrapper">
                            {
                                <img src={fileDataURL} alt="preview" height="300px" width="500px" />
                            }
                        </p> : null}
                    <form onSubmit={uploadFile}>
                        <Stack direction="column" gap={2} marginBottom="50px">
                            <FormControl>
                                <FormLabel >Picture</FormLabel>
                                <FormHelperText>Upload image for your asset</FormHelperText>
                                <Input
                                    type="file"
                                    name='photo'
                                    //value={img}
                                    border="none"
                                    p={1}
                                    mt={2}
                                    required={true}
                                    onChange={(e) => onImageChange(e)}
                                />
                            </FormControl>
                            <Checkbox defaultChecked={false} onChange={handleCheckBox}>Upload 3D asset</Checkbox>
                            {/* upload other files starts*/}
                            {check ?
                                <>
                                    <FormControl>
                                        {/* <FormLabel >Other File</FormLabel> */}
                                        <FormHelperText>Upload your file</FormHelperText>
                                        <Input
                                            type="file"
                                            name='photo'
                                            //value={img}
                                            border="none"
                                            p={1}
                                            mt={2}
                                            required={true}
                                            onChange={(e) => onOtherImageChange(e)}
                                        />
                                    </FormControl>
                                    <RadioGroup onChange={setRadioValue} value={radioValue}>
                                        <Stack direction='row'>
                                            <Radio value='Compatible with Unity engine'>Compatible with Unity engine</Radio>
                                            <Radio value='Compatible with Unreal engine'>Compatible with Unreal engine</Radio>
                                            <Radio value='Compatible with both Unity and Unreal'>Compatible with both Unity and Unreal</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </> : null
                            }
                            {/* upload other files ends*/}
                            <FormControl>
                                <FormLabel >NFT Name <span style={{ color: "red" }}>*</span></FormLabel>
                                <Input
                                    type="text"
                                    name='username'
                                    value={name ?? ""}
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel >Price <span style={{ color: "red" }}>*</span></FormLabel>
                                <Input
                                    type="text"
                                    name='price'
                                    value={priceNft ?? ""}
                                    required={true}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel >External Link</FormLabel>
                                <FormHelperText> Include a link to this URL on this item’s detail page, so that users can click to learn more about it. You are welcome to link to your own webpage with more details.</FormHelperText>
                                <Input
                                    type="text"
                                    name='link'
                                    value={link ?? ""}
                                    onChange={(e) => setLink(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel >Description <span style={{ color: "red" }}>*</span></FormLabel>
                                <FormHelperText>The description will be included on the item's detail page underneath its image. Markdown syntax is supported.</FormHelperText>
                                <Input
                                    type="text"
                                    name='description'
                                    value={description ?? ""}
                                    required={true}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel >Collection <span style={{ color: "red" }}>*</span></FormLabel>
                                <FormHelperText>This is the collection where your item will appear.</FormHelperText>
                                {location.state ?
                                    <>
                                        <Select value={location.state.data.category}

                                            onChange={(e) => setOption(location.state.data.category)}
                                            defaultValue={location.state.data.category}
                                        >
                                            <option value='art'>{location.state.data.category}</option>
                                        </Select>
                                    </>
                                    :
                                    <Select placeholder='Select option' value={option ?? ""}

                                        onChange={(e) => setOption(e.target.value)}
                                    >

                                        <>
                                            <option value='art'>Art</option>
                                            <option value='cartoon'>Cartoon</option>
                                            <option value='game'>Games</option>
                                            <option value='animal'>Animal</option>
                                            <option value='metaverse'>Metaverse</option>
                                        </>

                                    </Select>
                                }

                            </FormControl>
                            <FormControl>
                                <Flex alignItems="center" justifyContent="space-between">
                                    <Box alignItems="center">
                                        <HStack justifyContent="center" alignItems="top" gap={2}>
                                            <HamburgerIcon w="25px" h="25px" />
                                            <VStack align="left" >
                                                <Text fontWeight="bold">Properties</Text>
                                                <Text>Textual traits that show up as rectangles</Text>
                                            </VStack>
                                        </HStack>
                                    </Box>
                                    <Box border="1px solid gray" p="10px">
                                        <SmallAddIcon w="25px" h="25px" onClick={onOpen} />
                                    </Box>
                                </Flex>
                            </FormControl>
                            <FormControl>
                                <FormLabel >Blockchain <span style={{ color: "red" }}>*</span></FormLabel>
                                <Select value={blockchain}
                                    onChange={(e) => setblockchain(e.target.value)}
                                    required={true}
                                >
                                    <option value='algorand'>Algorand</option>
                                    <option value='ethereum'>Ethereum</option>
                                </Select>
                            </FormControl>
                            <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} type="submit" > Submit</Button>
                            {/* <Button onClick={()=>initialize_escrow(AppId,assetId)}>Initilize app</Button> */}
                        </Stack>
                        {
                            priceCheck ? <Alert status='warning'>
                                <AlertIcon />
                                You need to insert a valid price!!!
                            </Alert>
                                :
                                null
                        }
                        {
                            popUp ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    NFT Created successfully. Fire on!
                                </Alert>
                                :
                                null
                        }
                    </form>
                </Container>

            </Box>
            {/* Modal for properties */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Properties</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Divider marginBottom="20px" />
                        <Text marginBottom="20px">Properties show up underneath your item, are clickable, and can be filtered in your collection's sidebar.
                        </Text>
                        {inputFields.map((inputField, index) => (
                            <Box key={index} alignItems="center" p={5}>
                                <HStack gap={3} >
                                    <Input
                                        name='firstName'
                                        label="First Name"
                                        variant='filled'
                                        onChange={(e, index) => handleChangeInput(e, index)}
                                        width="33%"
                                        placeholder='Name'
                                        value={inputField.firstName}
                                    />
                                    <Input
                                        name='lastName'
                                        label="Last Name"
                                        variant='filled'
                                        onChange={e => handleChangeInput(e, index)}
                                        width="33%"
                                        placeholder='Age'
                                        value={inputField.lastName}
                                    />
                                    <IconButton onClick={() => handleRemove(index)}>
                                        <CloseIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleAdd()}>
                                        <SmallAddIcon />
                                    </IconButton>
                                </HStack>
                            </Box>
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleSubmit}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* ==================Loader Modal====================== */}
            <Modal isOpen={modal2.isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Creating NFT.....</ModalHeader>
                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================Loader Modal ends================= */}
        </>
    )
}
export default CreateItem;
