import React from 'react'
import { Box, Button, Divider, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import pago from '../images/Pago-Coin.png';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

function ThirdSlideForRepay() {
    const repayItemFromRedux = useSelector(state => state.token.repay_item);
    //const asset_id = repayItemFromRedux.assetID;
    const repayAmountFromRedux = useSelector(state => state.token.repay_amount);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    return (
        <>
            <TableContainer py="30px">
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Image</Th>
                            <Th>Collection/NFT Token</Th>
                            <Th >Debt Token Approval</Th>
                            <Th >NFT Tokens Approval</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>{repayItemFromRedux?.borrowItemImages ? repayItemFromRedux?.borrowItemImages.map((item) => (
                                <HStack style={{ display: "flex" }}> <Image src={item} width="50px" h="50px" objectFit="contain" /></HStack>

                            )) : <Image src={repayItemFromRedux.image} width="50px" h="50px" objectFit="contain" />}</Td>

                            <Td><Text>{repayItemFromRedux.name}</Text></Td>
                            <Td><Button>Next Step</Button></Td>
                            <Td><Button>Next Step</Button></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Divider />

            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                <Text fontSize="16px" fontWeight="600">Total Repayment</Text>
                <HStack>
                    <Image src={pago} w="25px" h="25px" />
                    <Text>{repayAmountFromRedux.toFixed(2)}</Text>
                </HStack>
            </HStack>
            <Divider />
            <VStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                <IoIosCheckmarkCircleOutline size="50" color='green' />
                <Text fontSize="12px" fontWeight="600" color="green.500">Next, you need to approve all Debt Tokens and all NFT Tokens.</Text>
                <Text fontSize="12px" fontWeight="600" color="green.500">You can now continue and repay your {repayAmountFromRedux} ALGO.</Text>

            </VStack>
        </>
    )
}

export default ThirdSlideForRepay
