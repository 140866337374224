import { Slide, Box, Button, Container, Divider, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, SimpleGrid, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import { ReactComponent as CoinImage } from '../components/images/Icons/algorand-algo-logo.svg';
import { ReactComponent as UsdcCoinImg } from '../components/images/Icons/usd-coin-usdc-logo.svg';
import { IoIosWallet, IoIosBonfire } from "react-icons/io";
import { useState } from 'react';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc, query, where
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import pago from '../components/images/Pago-Coin.png'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import algosdk, { getApplicationAddress } from 'algosdk';
import { rates } from '../components/UtilizationRate/UtilizationRateData';
import { NFTtotalBorrowAmount, NFTtotalDepositAmount, myAlgoWalletInfo, getAllAssetsAction } from '../components/redux/actions';
import Header from '../components/header/Header';
import BigNumber from 'bignumber.js';
import axios from 'axios';
import { API_URL } from '../url';

function isValidMicroAlgos(microalgos) {
    const maxMicroAlgos = new BigNumber(2).pow(253).minus(1);
    const microAlgosBN = new BigNumber(microalgos);
    return microAlgosBN.gte(0) && microAlgosBN.lt(maxMicroAlgos);
}
function Dashboard() {
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const appIdFromRedux = useSelector(state => state.token.borrow_app_id);
    const allAssets = useSelector(state => state.token.assets_from_account_2);
    const res = algoAdd.substring(0, 8);
    const lastFive = algoAdd.substr(algoAdd.length - 8);
    const [buyerInfo, setBuyerInfo] = useState([]);
    const [walletBalance, setWalletBalance] = useState([]);
    const [auctionInfo, setAuctionInfo] = useState([]);
    const nftCloseAuctionRef = collection(db, "closeAuction");
    const nftAuctionAddressRef = collection(db, "auctions");
    const borrowInfoRef = collection(db, "borrow-info");
    const [borrowInfo, setBorrowInfo] = useState()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const [nfts, setNfts] = useState([]);
    const nftCollectionRef = collection(db, "nfts");
    const nftDepositRef = collection(db, "deposit");
    const nftBorrowRef = collection(db, "borrow");
    const nftRangeRef = collection(db, "range");
    const [total_diposit_amount, setDiposit] = useState();
    const [total_borrow_amount, setBorrow] = useState();
    const [user_borrow_amount, setUserBorrow] = useState([]);
    const [pool_amount, setPool] = useState();
    const [APR, setValueAPR] = useState(5);
    const aprRateRef = collection(db, "borrowAPR");
    const textColor = useColorModeValue("gray.600", "whiteAlpha.500");
    const [rangeData, setRangeData] = useState();
    const [nftDetails, setNftDetails] = useState();
    const [rateB, setBorrowRate] = useState();
    const [init, setInit] = useState(true);
    const dispatch = useDispatch();
    const [withdrawAmt, setWithdrawAmt] = useState();
    const [withdraw, setWithdraw] = useState();
    const nftWithdrawRef = collection(db, "withdraw");
    const [deposit, setDepositState] = useState();
    const [userDeposit, setUserDeposit] = useState();
    let totalDipositAmount = 0;
    let totalBorrowAmount = 0;
    let totalWithdrawAmount = 0;
    const usdcAmount = allAssets.find((a) => a["asset-id"] == "10458941");
    const [acceptedCookies, setAcceptedCookies] = useState(false);
    const total_borrow = useSelector(state => state.token.total_borrow_amount);
    const total_deposit = useSelector(state => state.token.total_deposit_amount);
    /*
    ** Calling all necessary collection from firebase in useEffet hook
    */

    useEffect(() => {


        const accepted = localStorage.getItem('acceptedCookies');
        if (accepted) {
            setAcceptedCookies(true);
        }
    }, []);

    useEffect(() => {

        setBorrow(0);

        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef);
            setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getNfts();

        const allDeposit = async () => {
            const data = await getDocs(nftDepositRef);
            setDepositState(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        allDeposit();

        const allWithdraw = async () => {
            const data = await getDocs(nftWithdrawRef);
            setWithdraw(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        allWithdraw();

        const auctionAddress = async () => {
            const data = await getDocs(nftAuctionAddressRef);
            setAuctionInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        auctionAddress();

        const closeAuctionDetail = async () => {
            const data = await getDocs(nftCloseAuctionRef);
            setBuyerInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        closeAuctionDetail();

        const depositAmount = async () => {

            const data = await getDocs(nftDepositRef);
            data.docs.map((doc) => {
                totalDipositAmount += parseFloat(doc.data().amount)
                dispatch(NFTtotalDepositAmount(totalDipositAmount));
                setDiposit(totalDipositAmount);
            })
            setUserDeposit(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.account })));
        };
        let amount = 0;
        const borrowAmount = async () => {

            const data = await getDocs(nftBorrowRef);
            data.docs.map((doc) => {
                amount += parseFloat(doc.data().amount)
                setBorrow(amount);
                dispatch(NFTtotalBorrowAmount(amount));
            });

            let newBorrowAmounts = [];
            data.docs.forEach((doc) => {
                const borrowData = doc.data();
                newBorrowAmounts.push({
                    ...borrowData,
                    amount: parseFloat(borrowData.repay_remaining_amount),
                    id: doc.id,
                });
            });
            setUserBorrow(newBorrowAmounts);
            // setDepositState(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
        };

        depositAmount();
        borrowAmount();

        const pool_amount_func = async () => {
            const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');

            const escrowAddress = await getApplicationAddress(appIdFromRedux);


            let accountInfo = await algodClient.accountInformation(escrowAddress).do();

            let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount)
            //setDepositAccAmount(accountAmount);
            //dispatch(PoolAmountAction(accountAmount));
            setPool(accountAmount);
        }

        pool_amount_func();

        const aprAmount = async () => {
            let totalAPRAmount = 0;
            const data = await getDocs(aprRateRef);
            data.docs.map((doc) => {
                totalAPRAmount += parseInt(doc.data().amount)
                setValueAPR(totalAPRAmount)
                //dispatch(NFTtotalDepositAmount(totalDipositAmount));
            })
            // setDepositState(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
        };

        aprAmount();

        const withdrawAmount = async () => {

            const data = await getDocs(nftWithdrawRef);
            data.docs.map((doc) => {
                totalWithdrawAmount += parseInt(doc.data().withdrawAmount)
                setWithdrawAmt(totalWithdrawAmount);
                //dispatch(NFTtotalBorrowAmount(totalBorrowAmount));
            })
            // setDepositState(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
        };

        withdrawAmount();

        const getBorrowInfoFromFirebase = async () => {
            const data = await getDocs(borrowInfoRef);
            setBorrowInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowInfoFromFirebase();




        getUserBalance(algoAdd);
        fetchAndUpdateWalletData(algoAdd)
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('acceptedCookies', 'true');
        setAcceptedCookies(true);
    };

    async function getUserBalance(algoAdd) {
        try {
            const response = await axios(`${API_URL}swap/checkOptin?address=` + algoAdd);

            if (response.status === 200) {
                const optinResult = response.data;
                console.log(response.data);
                dispatch(myAlgoWalletInfo({
                    algoAddress: response.data.clientInfo.address,
                    algoBalance: response.data.clientInfo.amount / 1000000
                }));
                setWalletBalance(response.data.clientInfo.amount / 1000000)

            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    }

    async function fetchAndUpdateWalletData(algoAdd) {
        try {
            const response = await axios(`${API_URL}swap/checkOptin?address=` + algoAdd);

            if (response.status === 200) {
                const optinResult = response.data;

                // dispatch(myAlgoWalletInfo({
                //     algoAddress: address,
                //     algoBalance: response.data.clientInfo.amount / 1000000
                // }));

                dispatch(getAllAssetsAction({
                    assets_from_account: response.data.clientInfo["created-assets"],
                    assets_from_account_2: response.data.clientInfo.assets,
                }));
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    }


    const borrow_rate_new = borrowInfo?.map((item) => {
        if ((total_borrow_amount / pool_amount) < item.Uoptimal) {
            return item.R0 + ((total_borrow_amount / pool_amount) / item.Uoptimal) * item.Rslope1
        } else {
            return item.R0 + item.Rslope1 + ((total_borrow_amount / pool_amount) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
        }

    })

    const deposit_rate_new = (total_borrow_amount / pool_amount) * borrow_rate_new?.[0] * (1 - 0.3);
    const myBorrowAmount = user_borrow_amount ? user_borrow_amount.filter((data) => data.account === algoAdd).reduce((total, current) => total + current.amount, 0) : 0;
    const myDepo = deposit ? deposit.filter((data) => data.account === algoAdd) : null;
    const myWithDr = withdraw?.filter((item) => item.account === algoAdd);
    let myDeposit = 0;
    if (myDepo) {

        myDepo.map((data) => {
            myDeposit += parseInt(data.amount)
        })

    }

    let myWithdraw = 0;
    if (myWithDr) {

        myWithDr.map((data) => {
            myWithdraw += parseInt(data.amount)
        })

    }

    const userBalance = myDeposit - myWithdraw;

    const borrow_rate = nftDetails ? nftDetails.nftDetails.borrow_rate : 0;
    let borrowRate = undefined;
    let depoRate = undefined;


    const valueRate = rates.filter((data) => data.utilization === Math.round(total_borrow_amount / total_diposit_amount));

    //=========== checking user's nft - how many he/she has ================
    const userNFT = nfts.filter((data) => data.owner_address === algoAdd && data.borrow == false);
    //=========== checking user's nft - how many he/she has ends here==========


    //========= finding auction amount ===================
    let bidValue = 0;
    const auctions = auctionInfo.filter((data) => data.bidder_address === algoAdd);
    auctions.forEach(value => {
        bidValue += value.bidderAmount / 1000000;
    })
    //========= finding auction amount end=================

    const handleShowNft = () => {
        onOpen();
    }

    const handleNavigation = () => {
        navigate("/all-auction")

    }
    const handleRedirect = (item) => {
        navigate("/single", { state: { data: item } })
    }

    return (
        <>
            <Header />

            <Box minH="100vh" pt="50px">
                {/* first component start from here  */}

                <Box w="100%" >
                    <Container maxW={'5xl'} my="80px">
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                            <Box minH='300px' borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px">
                                <HStack justifyItems="center" justifyContent="space-between" py="20px" px="20px">
                                    <CoinImage width="40" height="40" fill="green" />
                                    {/*<Image src={coinImage} w="50px" h="50px" objectFit="cover" />*/}
                                    <Text fontSize="18px" fontWeight="600">{res}...{lastFive}</Text>
                                </HStack>
                                <Divider p="1px" color="gray.200" />
                                <HStack justifyItems="center" py="20px" align="left" px="20px">
                                    <Text fontSize="18px" fontWeight="600">Wallet Balances</Text>
                                </HStack>
                                <HStack justifyItems="center" py="5px" align="left" px="20px" justifyContent="space-between" alignItems="center">
                                    <HStack>
                                        <CoinImage width="30" height="30" fill="green" />
                                        {/*<Image src={coinImage} w="50px" h="50px" objectFit="cover" />*/}
                                        <Text fontSize="18px" fontWeight="600">ALGO</Text>
                                    </HStack>
                                    {/* <Text fontSize="18px" fontWeight="600">{algoBalance ? algoBalance.toFixed(2) : '0.00'}</Text> */}
                                    <Text fontSize="18px" fontWeight="600">{(walletBalance ? Number(walletBalance).toFixed(2) : '0.00')}</Text>
                                </HStack>


                                <HStack justifyItems="center" py="20px" align="left" px="20px">
                                    <Text fontSize="18px" fontWeight="600">USDC Balances</Text>
                                </HStack>
                                <HStack justifyItems="center" py="5px" align="left" px="20px" justifyContent="space-between" alignItems="center">
                                    <HStack>
                                        <UsdcCoinImg width="30" height="30" fill="currentColor" />
                                        {/*<Image src={usdcCoinImg} w="50px" h="50px" objectFit="cover" />*/}
                                        <Text fontSize="18px" fontWeight="600">USDC</Text>
                                    </HStack>
                                    <Text fontSize="18px" fontWeight="600">{usdcAmount && isValidMicroAlgos(usdcAmount.amount) ? algosdk.microalgosToAlgos(usdcAmount.amount).toFixed(2) : 'You dont have any USDC!'}</Text>
                                </HStack>

                                {/* <Button w="70%" alignItems="center" mx="20px">Convert</Button> */}

                            </Box>
                            <Box minH='300px' borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px">
                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <HStack justify="center" justifyContent="center">
                                        <Image src={pago} w="30px" h="30px" objectFit="cover" mt="-20px" />
                                        <VStack align="left">
                                            <Text fontSize="22px" fontWeight="600" mb="-10px">My Borrows</Text>
                                            <Text fontSize="16px" fontWeight="300">Total debt</Text>
                                        </VStack>
                                    </HStack>
                                    <HStack>
                                        <Image src={pago} w="25px" h="25px" objectFit="contain" />
                                        <Text fontWeight="bold">{myBorrowAmount ? myBorrowAmount.toFixed(2) : "0.00"}</Text>
                                    </HStack>

                                </HStack>
                                <Divider p="1px" color="gray.200" />
                                <VStack align="left" px="20px" spacing={3} py="20px">
                                    <Text fontSize="21px" fontWeight="bold"> Borrow APR</Text>

                                    {/* <Text fontSize="21px" fontWeight="bold">{((((total_borrow_amount + (total_borrow_amount * (borrow_rate / 100)) + ((total_borrow_amount * (borrow_rate / 100) * 0.3))) - total_borrow_amount) / total_borrow_amount) * 100).toFixed(2)}%</Text> */}
                                    <Text fontSize="21px" fontWeight="bold">{(borrow_rate_new?.[0] * 100).toFixed(2)}%</Text>

                                    <HStack>
                                        <Image src={pago} w="25px" h="25px" objectFit="cover" />
                                        <Text fontWeight="bold">{myBorrowAmount ? myBorrowAmount.toFixed(2) : "0.00"}</Text>k
                                    </HStack>
                                </VStack>
                                <Divider p="1px" color="gray.200" />

                                <HStack py="40px" justifyItems="center" justifyContent="space-evenly">
                                    <Link to="/borrow">
                                        <Button p="30px" borderRadius="25px" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>Borrow ALGO</Button>
                                    </Link>
                                    <Link to="/mynfts">

                                        <Button p="30px" borderRadius="25px" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}> My Borrow</Button>
                                    </Link>
                                </HStack>


                            </Box>
                            {/* ==============showing owner NFTs ============================= */}
                            <Box minH='150px' borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" onClick={handleShowNft} cursor="pointer">

                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <HStack>
                                        <Image src={pago} w="30px" h="30px" objectFit="cover" mt="-20px" />
                                        <VStack align="left">
                                            <Text fontSize="22px" fontWeight="600" mb="-5px">My NFTs</Text>
                                            <Text fontSize="16px" fontWeight="300">Total NFTs</Text>
                                        </VStack>
                                    </HStack>
                                    <HStack>
                                        {/* <Image src={coinImage} w="15px" h="15px" objectFit="cover" /> */}
                                        <IoIosBonfire w="15px" h="15px" />
                                        <Text fontWeight="bold">{userNFT.length}</Text>
                                    </HStack>

                                </HStack>
                            </Box>
                            {/* ==============showing owner NFTs ends here============================= */}
                            <Box minH='150px' borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" cursor="pointer" onClick={handleNavigation}>

                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <HStack>
                                        <Image src={pago} w="30px" h="30px" objectFit="cover" mt="-20px" />
                                        <VStack align="left">
                                            {/* <Link to="/all-auction"> */}
                                            <Text fontSize="22px" fontWeight="600" mb="-5px">Auctions</Text>
                                            {/* </Link> */}
                                            <Text fontSize="16px" fontWeight="300">Total bid value:</Text>
                                        </VStack>
                                    </HStack>
                                    <HStack>
                                        {/* <Image src={coinImage} w="15px" h="15px" objectFit="cover" /> */}
                                        <IoIosWallet w="15px" h="15px" />
                                        <Text fontWeight="bold">{bidValue * 1000000}</Text>
                                    </HStack>

                                </HStack>
                            </Box>

                            <Box minH='300px' borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px">
                                <HStack justifyItems="center" py="17px" px="20px">
                                    <Image src={pago} w="30px" h="30px" objectFit="cover" />
                                    <VStack align="left">
                                        <Text fontSize="22px" fontWeight="600">ALGO Pool Info</Text>

                                    </VStack>
                                </HStack>
                                <Divider p="1px" color="gray.200" />
                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <Text fontSize="18px" fontWeight="bold">Borrow APR</Text>


                                    {/* <Text fontSize="21px" fontWeight="bold">{((((total_borrow_amount + (total_borrow_amount * (borrow_rate / 100)) + ((total_borrow_amount * (borrow_rate / 100) * 0.3))) - total_borrow_amount) / total_borrow_amount) * 100).toFixed(2)}%</Text> */}
                                    <Text fontSize="21px" fontWeight="bold">{(borrow_rate_new?.[0] * 100).toFixed(2)}%</Text>



                                </HStack>
                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <Text fontSize="18px" fontWeight="bold">Deposit APR</Text>

                                    {/* <Text fontSize="21px" fontWeight="bold">{((((total_borrow_amount + (total_borrow_amount * (borrowRate / 100)) + ((total_borrow_amount * (borrowRate / 100) * 0.3))) - total_borrow_amount) / total_borrow_amount) * 100).toFixed(2)}%</Text> */}
                                    {/* <Text fontSize="21px" fontWeight="bold">{depoRate}</Text> */}
                                    <Text fontSize="21px" fontWeight="bold">{deposit_rate_new ? (deposit_rate_new * 100).toFixed(2) : '0'}%</Text>

                                </HStack>
                                <Divider p="1px" color="gray.200" />
                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <Text fontSize="18px" fontWeight="bold">Total Liquidity</Text>
                                    <Text fontSize="21px" fontWeight="bold">{(pool_amount) ? (pool_amount).toFixed(2) : '0.00'}</Text>
                                </HStack>
                                <Divider p="1px" color="gray.200" />
                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <Text fontSize="18px" fontWeight="bold">Total Lending</Text>
                                    <Text fontSize="21px" fontWeight="bold">{(total_borrow_amount) ? (total_borrow_amount).toFixed(2) : '0.00'}</Text>
                                </HStack>
                                <Divider p="1px" color="gray.200" />

                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <Text fontSize="18px" fontWeight="bold">Utilization Rate</Text>
                                    <Text fontSize="21px" fontWeight="bold">{((total_borrow_amount / pool_amount) * 100).toFixed(2)}
                                        %</Text>
                                </HStack>




                            </Box>

                            <Box minH='300px' borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px">
                                <HStack justifyItems="center" py="17px" px="20px">
                                    <Image src={pago} w="30px" h="30px" objectFit="cover" />
                                    <VStack align="left">
                                        <Text fontSize="22px" fontWeight="600">Deposit Info</Text>

                                    </VStack>
                                </HStack>
                                <Divider p="1px" color="gray.200" />
                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <Text fontSize="18px" fontWeight="bold">My Total Deposit</Text>
                                    <Text fontSize="21px" fontWeight="bold">{userBalance}</Text>

                                </HStack>
                                <Divider p="1px" color="gray.200" />
                                <HStack justifyItems="center" justifyContent="space-between" py="17px" px="20px">
                                    <Text fontSize="18px" fontWeight="bold">Total Pool Balance</Text>
                                    <Text fontSize="21px" fontWeight="bold">{(pool_amount) ? (pool_amount).toFixed(2) : '0.00'}</Text>
                                </HStack>

                            </Box>

                        </SimpleGrid>
                    </Container>
                </Box>
            </Box>

            {!acceptedCookies && (
                <Slide direction="bottom" in={true} style={{ zIndex: 10 }}>
                    <Box
                        p={4}
                        color="white"
                        mt="4"
                        bg="teal.500"
                        rounded="md"
                        shadow="md"
                    >
                        We use cookies to improve your experience. By your continued use of this site you accept such use.
                        <Button onClick={acceptCookies} colorScheme="whiteAlpha">
                            Accept
                        </Button>
                    </Box>
                </Slide>
            )}

            {/* =======================showing NFTs modal======================== */}
            <Modal isOpen={isOpen} onClose={onClose} width="700px">
                <ModalOverlay />
                <ModalContent minH="300px">
                    <ModalHeader>MY NFTs</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer >
                            <Table  >
                                <Thead>
                                    <Tr h="50px">
                                        <Th w="15%">Image</Th>
                                        <Th w="15%">Name</Th>
                                        <Th w="15%">Price</Th>

                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {userNFT != [] ? userNFT.map((item) => (
                                        <Tr h="80px" key={item.id}>
                                            <Td><Image src={item.url} h="30px" w="30px" /></Td>
                                            <Td><Text color="blue.400" cursor="pointer" onClick={() => handleRedirect(item)}>{item.name} </Text></Td>
                                            <Td isNumeric>{item.price}</Td>

                                        </Tr>
                                    )) : <><Text>There is no NFT to show</Text></>}


                                </Tbody>

                            </Table>
                        </TableContainer>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* =======================showing NFTs modal ends======================== */}
        </>
    )
}

export default Dashboard
