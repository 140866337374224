import React from 'react';
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    useColorModeValue,
    useDisclosure,
    VStack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import FirstSlide from "./FirstSlide";
import SecondSlide from "./SecondSlide";
import TrirdSlide from "./TrirdSlide";
import { useSelector } from 'react-redux';
import algosdk, { bigIntToBytes, getApplicationAddress, decodeAddress, waitForConfirmation } from "algosdk";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NFTborrowItemAction, supplyAmountAction, ResetStateAction } from "../redux/actions";
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update,
    Timestamp
} from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import TokenForBorrow from "../Token/TokenForBorrow";
import axios from 'axios';
import { myAlgoWalletInfo } from "../redux/actions";
import { API_URL } from '../../url';
import { useDispatch } from "react-redux";
import { peraWallet } from "../../pages/WalletNew";
import { toast } from "react-toastify"
//import { PeraWalletConnect } from "@perawallet/connect";

//const peraWallet = new PeraWalletConnect();

function FirstComponent() {
    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    })
    const [isFirstStepNextButtonDisabled, setIsFirstStepNextButtonDisabled] = useState(true);
    const [isThirdStepNextButtonDisabled, setIsThirdStepNextButtonDisabled] = useState(true);
    const steps = [
        {
            label: "Select NFT",
            component: (props) => <FirstSlide {...props} setSelectedNFTs={setSelectedNFTs} setShowAlert={setShowAlert} setIsFirstStepNextButtonDisabled={setIsFirstStepNextButtonDisabled} />,
        },
        {
            label: "Supply Token",
            component: (props) => <TokenForBorrow {...props} />,
        },
        {
            label: "Borrow Amount",
            component: (props) => <SecondSlide {...props} setShowAlert={setShowAlert} setIsThirdStepNextButtonDisabled={setIsThirdStepNextButtonDisabled} />,
        },
        {
            label: "Approve Borrowing",
            component: (props) => <TrirdSlide {...props} checkBorrowValidity={checkBorrowValidity} />,
        },
    ];
    const [showAlert, setShowAlert] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const checkbox = useSelector(state => state.token.nft_checkbox);
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [isNextButtonDisabledTwo, setIsNextButtonDisabledTwo] = useState(false);
    const borrow_Amount = useSelector(state => state.token.nft_borrow_amount);
    const supply_amount_with_interest = useSelector(state => state.token.supply_amount); //why is this set to the same redux state as supply_amount?
    const supply_amount_from_user = useSelector(state => state.token.supply_amount_from_user);
    const borrowItemFromRedux = useSelector(state => state.token.borrow_item);
    const supply_amount = useSelector(state => state.token.supply_amount);

    const assets = borrowItemFromRedux;
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const [finalStep, setFinalStep] = useState(false);
    const navigate = useNavigate();
    const [item2, setItem2] = useState(false);
    const modal2 = useDisclosure();
    const modal3 = useDisclosure();
    const modal4 = useDisclosure();
    const nftCollectionRef = collection(db, "nfts");
    const nftBorrowtRef = collection(db, "borrow");
    const nftDepositRef = collection(db, "deposit");
    const aprRateRef = collection(db, "borrowAPR");
    const borrowInfoRef = collection(db, "borrow_info_usdc");
    //const borrowInfoRef = collection(db, "borrow-info");
    const [totalBorrow, setBorrow] = useState();
    const [deposit, setDeposit] = useState();
    const [borrowInfo, setBorrowInfo] = useState();
    const [borrowInfoId, setID] = useState();
    const pool_token = useSelector(state => state.token.borrow_app_id);
    const asset_id = 0;
    const dispatchInput = useDispatch();
    const [borrowIsValid, setBorrowIsValid] = useState(false);
    const [isThirdStepValid, setIsThirdStepValid] = useState(false);

    const checkBorrowValidity = (isValid) => {
        setIsNextButtonDisabled(!isValid);
    };

    //    "====isFirstStepNextButtonDisabled ====", isFirstStepNextButtonDisabled)
    const handleResetState = () => {
        dispatchInput(ResetStateAction());
    };
    const handleBorrow = () => {
        borrow(assets);
    }

    const [nftDetails, setNftDetails] = useState();
    const [nfts, setNfts] = useState([]);
    const textColor = useColorModeValue("gray.600", "whiteAlpha.900");
    let total_Diposit_Amount = 0;
    let total_Borrow_Amount = 0;
    useEffect(() => {
        handleResetState();
        const getNfts = async () => {

            const q = query(collection(db, "nfts"));
            const data = await getDocs(q);
            const q2 = query(collection(db, "nfts"), where("assetID", "==", asset_id));
            //const q2 = query(collection(db, "nfts"), where("assetID", "==", borrowItemFromRedux ? borrowItemFromRedux?.[0].assetID : 0));
            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                let docData = doc.data();
                await setNftDetails({ ...nftDetails, nftDetails: docData, nftId: doc.id })

            });

            setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

        };

        getNfts();

        const getAllBorrowInfo = async () => {

            const q2 = query(collection(db, "borrow_info_usdc"), where("initial_id", "==", "1"));
            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                let docData = doc.data();
                await setID({ ...nftDetails, nftDetails: docData, nftId: doc.id })

            });

        };

        getAllBorrowInfo();

        const borrowAmount = async () => {
            const data = await getDocs(nftBorrowtRef);
            data.docs.map((doc) => {
                total_Borrow_Amount += parseFloat(doc.data().amount)
                setBorrow(total_Borrow_Amount);
            })
        };

        borrowAmount();
        const depositAmount = async () => {
            const data = await getDocs(nftDepositRef);
            data.docs.map((doc) => {
                total_Diposit_Amount += parseFloat(doc.data().amount)
                setDeposit(total_Diposit_Amount);
            })
        };

        depositAmount();

        const getBorrowInfoFromFirebase = async () => {
            const data = await getDocs(borrowInfoRef);
            setBorrowInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowInfoFromFirebase();

        return () => {
            handleResetState();
        };

    }, [borrowItemFromRedux?.assetID]);

    useEffect(() => {
        if (activeStep === 0) {
            setSelectedNFTs([]);
            setShowAlert(false);
        }
    }, [activeStep]);

    /*
    useEffect(() => {
        setIsFirstStepNextButtonDisabled(selectedNFTs.length === 0);
    }, [selectedNFTs]);

     */

    const findAlgoValue = borrowInfo?.filter((item) => item.type === "algo")
    const borrow_rate_new = findAlgoValue?.map((item) => {
        if ((totalBorrow / deposit) < item.Uoptimal) {
            return item?.R0 + ((totalBorrow / deposit) / item?.Uoptimal) * item?.Rslope1
        } else {
            return item?.R0 + item?.Rslope1 + ((totalBorrow / deposit) - item?.Uoptimal) / (1 - item?.Uoptimal) * item?.Rslope2
        }

    })

    const assetOptin = async (asset_id) => {
        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const params = await algodClient.getTransactionParams().do();
        var note = new Uint8Array([10]);

        let sender = algoAdd;
        let recipient = sender;
        let revocationTarget = undefined;
        let closeRemainderTo = undefined;
        // We are sending 0 assets
        let amount = 0;
        let assetID = 10458941;
        // signing and sending "txn" allows sender to begin accepting asset specified by creator and index
        let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(
            sender,
            recipient,
            closeRemainderTo,
            revocationTarget,
            amount,
            note,
            assetID,
            params);

        const myAlgoConnect = new MyAlgoConnect();
        const signedTxn = await peraWallet.signTransaction([{ txn: txn, signers: [algoAdd] }]);
        //const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());
        const response = await algodClient.sendRawTransaction(signedTxn.blob).do();

        //setItem1(true);
        setTimeout(() => {
            //setPopUp(false);
            borrow(asset_id);
        }, 2000);
        ////console.log(response)

    }

    const borrow = async (assets) => {
        try {
            const asset_id = borrowItemFromRedux?.length > 0 ? borrowItemFromRedux[0].assetID : 0;
            let doc_id = undefined;
            // gettting nft ID start from here
            const q = query(collection(db, "nfts"));
            const data = await getDocs(q);
            ////console.log("checking asset ID " + asset_id)
            const q2 = query(collection(db, "nfts"), where("assetID", "==", asset_id));
            //const q2 = query(collection(db, "nfts"), where("assetID", "==", borrowItemFromRedux ? borrowItemFromRedux?.[0].assetID : 0));
            ////console.log("========hello, i am here========")
            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                let docData = doc.data();
                await setNftDetails({ ...nftDetails, nftDetails: docData, nftId: doc.id })
                doc_id = doc.id;
            });
            // gettting nft ID end here
            //console.log(doc_id);
            // checking if usdc have or not
            let assetIDArray = [];
            if (supply_amount_from_user > 0) {
                assets.forEach(assetItem => {
                    assetIDArray.push(assetItem.assetID);
                });
                assetIDArray.push(Number(process.env.REACT_APP_USDC_ID));
            } else {

                assets.forEach(assetItem => {
                    assetIDArray.push(assetItem.assetID);
                });
            }

            // getting all asset images
            let images = [];
            borrowItemFromRedux.forEach(assetItem => {
                images.push(assetItem.url);
            });

            if (borrow_Amount > 0) {
                modal4.onOpen();

                const appId = pool_token;
                const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
                const suggested_params = await algodClient.getTransactionParams().do();
                const creator = algoAdd;
                const escrowAddress = await getApplicationAddress(appId);
                const borrow = new Uint8Array(Buffer.from('borrow', 'utf8'));
                const assetOptin = new Uint8Array(Buffer.from('assetOptin', 'utf8'));

                const totalAmount = bigIntToBytes(parseInt(borrow_Amount), 8)

                const total_borrow_amount = bigIntToBytes(parseInt(borrow_Amount * 1000000), 8)

                let approval_program_compiled = undefined
                let clear_program_compiled = undefined
                let numLocalByteSlices = undefined
                let numGlobalInts = undefined
                let numGlobalByteSlices = undefined
                let numLocalInts = undefined

                await axios(`${API_URL}swap/escrowProgram`)
                    .then(response => {

                        if (response.status === 200) {

                            let data = response.data;
                            approval_program_compiled = data.approval_program_compiled
                            clear_program_compiled = data.clear_program_compiled
                            numLocalInts = data.local_schema.num_uints
                            numLocalByteSlices = data.local_schema.num_byte_slices
                            numGlobalInts = data.global_schema.num_byte_slices
                            numGlobalByteSlices = data.global_schema.num_uints
                            // ////console.log(data)
                        }

                    })
                    .catch(error => {
                        console.error("Error fetching data", error);

                    })

                const approvalcompiledProgram = await algodClient.compile(approval_program_compiled).do();
                const clearcompiledProgram = await algodClient.compile(clear_program_compiled).do();
                const approvalProgramBytes = new Uint8Array(
                    Buffer.from(approvalcompiledProgram.result, 'base64')
                );
                const clearlProgramBytes = new Uint8Array(
                    Buffer.from(clearcompiledProgram.result, 'base64')
                );
                let escrowAddressD = decodeAddress(escrowAddress)
                let algoAddD = decodeAddress(algoAdd)

                let asset = bigIntToBytes(asset_id, 8);

                let minReservePercentage = bigIntToBytes(10, 8);

                let minimunReserveAmount = bigIntToBytes(0, 8);

                let loanToValueRatio = bigIntToBytes(40, 8);

                let multiplier = bigIntToBytes(1000000, 8);

                let hasAsa = bigIntToBytes(1, 8);
                let hasUSDC = bigIntToBytes(1, 8);

                const logicSigInfo = await escrow_lsg_address();
                let lsig = new algosdk.LogicSigAccount(logicSigInfo)


                let txn = algosdk.makeApplicationCreateTxnFromObject({
                    from: lsig.address(),
                    suggestedParams: suggested_params,
                    approvalProgram: approvalProgramBytes,
                    clearProgram: clearlProgramBytes,
                    numLocalInts: numLocalInts,
                    numLocalByteSlices: numLocalByteSlices,
                    numGlobalInts: numGlobalInts,
                    numGlobalByteSlices: numGlobalByteSlices,
                    appArgs: [escrowAddressD.publicKey, escrowAddressD.publicKey, algoAddD.publicKey, hasAsa, hasUSDC],
                    foreignAssets: assetIDArray,

                    onComplete: 0
                })

                const myAlgoConnect = new MyAlgoConnect();

                // const signedTxn1 = await myAlgoConnect.signTransaction(txn.toByte());
                let signedTxn1 = algosdk.signLogicSigTransactionObject(txn, lsig);

                const response1 = await algodClient.sendRawTransaction(signedTxn1.blob).do();

                const appInfo = await waitForConfirmation(algodClient, response1.txId, 5);

                const escrow_app_id = appInfo['application-index'];

                const escrow_app_address = getApplicationAddress(escrow_app_id);
                ////console.log("==================", escrow_app_id);
                //return 0;
                //============== working for supply amount ====================
                //console.log("=============================", supply_amount_from_user);
                const params = await algodClient.getTransactionParams().do();
                var note = new Uint8Array([10]);
                let sender = algoAdd;
                let recipient = escrow_app_address;
                let revocationTarget = undefined;
                let closeRemainderTo = undefined;
                // We are sending 0 assets
                let amount = supply_amount_from_user;
                let assetID = Number(process.env.REACT_APP_USDC_ID);
                let multipleTxnGroupsDynamic = [];
                let groupTxns = [];

                let fundAppTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                    from: algoAdd,
                    to: escrow_app_address,
                    amount: 500000,
                    //amount :1000000,
                    suggestedParams: suggested_params

                })

                multipleTxnGroupsDynamic.push({
                    txn: fundAppTxn, signers: [algoAdd]

                });
                groupTxns.push(fundAppTxn);



                assetIDArray.forEach(element => {
                    let optinAsset = bigIntToBytes(element, 8);

                    let txn0 = algosdk.makeApplicationCallTxnFromObject({
                        from: creator,
                        appIndex: escrow_app_id,
                        onComplete: 0,
                        appArgs: [assetOptin, optinAsset],
                        suggestedParams: suggested_params,
                        foreignAssets: [element]

                    })

                    multipleTxnGroupsDynamic.push({
                        txn: txn0, signers: [algoAdd]

                    });

                    groupTxns.push(txn0);
                });



                assetIDArray.forEach(element => {

                    if (element == Number(process.env.REACT_APP_USDC_ID)) {

                        let txnAstTransfr = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                            from: sender,
                            suggestedParams: {
                                ...suggested_params
                            },
                            to: recipient,
                            amount: amount * 1000000,
                            assetIndex: element,
                            revocationTarget: revocationTarget,
                            foreignAssets: [element]

                        });

                        multipleTxnGroupsDynamic.push({
                            txn: txnAstTransfr, signers: [algoAdd]

                        });

                        groupTxns.push(txnAstTransfr);

                    }
                    else {

                        const txnAstConf = algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject({
                            from: algoAdd,
                            suggestedParams: suggested_params,
                            assetIndex: element,
                            reserve: undefined,
                            freeze: undefined,
                            // clawback: escrowAddress,
                            clawback: escrow_app_address,
                            manager: escrow_app_address,
                            strictEmptyAddressChecking: false
                        });
                        const txnNftTransfr = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                            from: algoAdd,
                            suggestedParams: suggested_params,
                            to: escrow_app_address,
                            amount: 1,
                            assetIndex: element,
                        });

                        multipleTxnGroupsDynamic.push({
                            txn: txnAstConf, signers: [algoAdd]
                        });
                        multipleTxnGroupsDynamic.push({
                            txn: txnNftTransfr, signers: [algoAdd]
                        });

                        groupTxns.push(txnAstConf);
                        groupTxns.push(txnNftTransfr);

                    }

                });

                let applicationInfoResponse = await algodClient.accountApplicationInformation("RRO7OHROIWSOA7YXJBACWXOTCG4BWX3XLNSJLTJUSPRI5KHP3JXUTDM63Q", appId).do();
                let totalBorrowedAmount = 0


                for (let i = 0; i < applicationInfoResponse['created-app']['global-state'].length; i++) {

                    if (applicationInfoResponse['created-app']['global-state'][i].key == "VE9UQUxfQk9SUk9XX0FNT1VOVA==") {
                        totalBorrowedAmount = applicationInfoResponse['created-app']['global-state'][i].value.uint
                    }

                }

                totalBorrowedAmount = parseInt(totalBorrowedAmount) + parseInt(totalAmount)

                totalBorrowedAmount = bigIntToBytes(parseInt(totalBorrowedAmount), 8)
                const txn2 = algosdk.makeApplicationCallTxnFromObject({
                    from: creator,
                    appIndex: appId,
                    onComplete: 0,
                    appArgs: [borrow, totalAmount, totalBorrowedAmount],
                    suggestedParams: suggested_params

                })

                multipleTxnGroupsDynamic.push({
                    txn: txn2, signers: [algoAdd]

                });

                groupTxns.push(txn2);

                //console.log(multipleTxnGroupsDynamic)

                //console.log(groupTxns);

                const groupID = algosdk.assignGroupID(groupTxns)

                modal4.onClose();
                const signedTxn = await peraWallet.signTransaction([multipleTxnGroupsDynamic]);

                const signedIndexArr = [];
                for (let index = 0; index < signedTxn.length; index++) {
                    signedIndexArr.push(signedTxn[index])

                }
                const response = await algodClient.sendRawTransaction(signedIndexArr).do();

                //console.log("txnid")
                //console.log(response);

                //modal4.onClose();

                const currentTime = Date.now();
                const change_time = ((currentTime - findAlgoValue[0].change_in_time_new) * 0.001)
                const alpha = borrow_rate_new[0] / (365 * 24 * 60 * 60);

                const index = findAlgoValue[0].initial_borrow_index * (1 + findAlgoValue[0].sigma * (alpha * change_time));
                //alert(index);


                await addDoc(nftBorrowtRef, { amount: borrow_Amount, account: algoAdd, app_id: appId, image: borrowItemFromRedux ? borrowItemFromRedux[0].url : "https://unsplash.com/photos/peaTniZsUQs", name: borrowItemFromRedux ? borrowItemFromRedux[0].name : "test", repay: false, repayAmount: 0, repay_remaining_amount: borrow_Amount, escrow_app_id: escrow_app_id, nft_price: borrowItemFromRedux ? borrowItemFromRedux[0].price : 2, supplyA_amount_interest: supply_amount_with_interest, supply_amount_by_user: supply_amount_from_user, assetID: assetIDArray, assetIdForRepay: asset_id, borrowItemImages: images });

                await assetIDArray.forEach(async (element) => {
                    const q2 = query(collection(db, "nfts"), where("assetID", "==", element));
                    const data2 = await getDocs(q2);

                    await data2.forEach(async (doc2) => {
                        let docData = doc2.data();
                        const userDoc = doc(db, "nfts", doc2.id);
                        await updateDoc(userDoc, {
                            borrow: true,
                            repayAmount: borrow_Amount,
                            repay_remaining_amount: borrow_Amount,
                            assetIdForRepay: asset_id
                        });

                    });
                });

                const userDocBorrowInfo = doc(db, "borrow_info_usdc", "w0UGGouZ9uaA8hWuLVRc");
                //const userDoc = doc(db, "nfts", nftDetails.nftId);
                //await updateDoc(userDoc, { borrow: true, repayAmount: borrow_Amount, repay_remaining_amount: borrow_Amount, assetIdForRepay: asset_id });
                //const userDocBorrowInfo = doc(db, "borrow_info_usdc", borrowInfoId.nftId);
                await updateDoc(userDocBorrowInfo, { initial_borrow_index: index, change_in_time_new: currentTime });

                setItem2(true);
                modal2.onOpen();
                setTimeout(() => {
                    //setPopUp(false);
                    modal2.onClose();
                }, 6000);
                setTimeout(() => {
                    //setPopUp(false);
                    navigate('/dashboard');
                }, 2000);
                setTimeout(() => {
                    updateAccInfo();

                }, 7000);
            } else {
                modal3.onOpen();
            }
        } catch (error) {
            toast.error("ERROR, in Borrow Offer.")
        }

    }
    const escrow_lsg_address = async () => {

        let escrow_fund_program_compiled = undefined
        let escrow_address = undefined
        await axios(`${API_URL}swap/escrowLogicSig`)
            //await axios(`http://127.0.0.1:12000/swap/escrowLogicSig`)
            .then(response => {

                if (response.status === 200) {
                    let data = response.data;
                    escrow_fund_program_compiled = data.escrow_fund_program_compiled
                    escrow_address = data.escrow_address
                }

            })
            .catch(error => {
                toast.error("ERROR, in Borrow Offer.")
            })

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');

        const escrowlcompiledProgram = await algodClient.compile(escrow_fund_program_compiled).do();

        const escrowProgramBytes = new Uint8Array(
            Buffer.from(escrowlcompiledProgram.result, 'base64')
        );

        return escrowProgramBytes;

    }
    const updateAccInfo = async () => {
        let accInfo = {};
        await axios(`${API_URL}swap/accountInfo?address=` + algoAdd)
            .then(response => {

                if (response.status === 200) {
                    accInfo = response.data;
                    const address = accInfo.clientInfo.address;
                    const amount = accInfo.clientInfo.amount / 1000000;
                    dispatchInput(myAlgoWalletInfo({
                        algoAddress: address,
                        algoBalance: amount
                    }));
                }

            })
            .catch(error => {
                console.error("Error fetching data", error);
            })
    }
    const f1 = () => {

        if (activeStep === 0 && selectedNFTs.length < 1) {
            setShowAlert(true)
        }
        else if (activeStep === 2 && !isThirdStepNextButtonDisabled) {
            setShowAlert2(true)
        } else {
            activeStep === steps.length ? handleBorrow() : nextStep()
        }

    }
    // const f2 = () => {

    //     if (activeStep === 2 && !isThirdStepNextButtonDisabled) {
    //         setShowAlert(true)
    //     } else {

    //     }
    // }

    return (
        <>
            <Flex flexDir="column" width="100%" my="1px">
                <Steps activeStep={activeStep}>
                    {steps.map(({ label, component }, index) => (
                        <Step label={label} key={label}>
                            {component()}
                        </Step>
                    ))}
                </Steps>


                {activeStep === steps.length ? (
                    <Flex px={4} py={4} width="100%" flexDirection="column">
                        <Heading fontSize="xl" textAlign="center">
                            Woohoo! All pre-steps completed!
                        </Heading>
                        <Text textAlign="center" fontWeight="bold">
                            Please click on the Borrow button
                            to sign the transaction using your wallet!
                        </Text>
                        <Flex width="100%" justify="space-between" py="20px">
                            <Button
                                isDisabled={activeStep === 0}
                                mr={4}
                                size="md"
                                variant="ghost"
                                width="50%"
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                                onClick={reset}
                            >
                                Reset
                            </Button>
                            <Button
                                isDisabled={activeStep === 0}
                                mr={4}
                                size="md"
                                variant="ghost"
                                width="50%"
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                                onClick={handleBorrow}
                            >
                                Borrow
                            </Button>
                        </Flex>
                    </Flex>

                ) :

                    (
                        <Flex width="100%" justify="space-between">
                            <Button
                                isDisabled={activeStep === 0}
                                mr={4}
                                onClick={prevStep}
                                //onClick={() => { prevStep() }}
                                size="md"
                                variant="ghost"
                                width="50%"
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                            >
                                Prev
                            </Button>
                            {/* <Button isDisabled={activeStep === 3 ? isNextButtonDisabled : activeStep === 0 && isFirstStepNextButtonDisabled} size="md" onClick={activeStep === 0 && selectedNFTs.length < 1
                                ? () => setShowAlert(true)
                                : () => (activeStep === steps.length ? handleBorrow : nextStep)()} width="50%"
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900"
                                _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                            >
                                {activeStep === steps.length - 1 ? "Next" : "Next"}
                            </Button> */}



                            {/* ============ test code ================*/}


                            <Button isDisabled={activeStep === 3 ? isNextButtonDisabled : activeStep === 0 && isFirstStepNextButtonDisabled} size="md"
                                onClick={() =>
                                    f1()

                                }

                                width="50%"
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900"
                                _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                            >
                                {activeStep === steps.length - 1 ? "Next" : "Next"}
                            </Button>



                            {/* ============ test code end================*/}


                        </Flex>
                    )}
                {/* {activeStep === 0 ? handleReduxReset() : ""} */}

            </Flex>

            {showAlert && (
                <Alert status="error" borderRadius="15px" mb="1em">
                    <AlertIcon />
                    <AlertTitle mr={2}>Error!</AlertTitle>
                    <AlertDescription>Please select at least one NFT.</AlertDescription>
                    <CloseButton position="absolute" right="8px" top="8px" onClick={() => setShowAlert(false)} />
                </Alert>
            )}

            {showAlert2 && (
                <Alert status="error" borderRadius="15px" mb="1em">
                    <AlertIcon />
                    <AlertTitle mr={2}>Error!</AlertTitle>
                    <AlertDescription>You need to insert an amount</AlertDescription>
                    <CloseButton position="absolute" right="8px" top="8px" onClick={() => setShowAlert2(false)} />
                </Alert>
            )}

            {/* ==================successful Borrow offer modal start=================== */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="400px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Congratulations!!</Text>
                                <Text color={textColor}>You successfully made the BORROW OFFER.</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================successful Borrow offer modal ends=================== */}

            <Modal isOpen={modal3.isOpen} onClose={modal3.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Oooppppsss!!</Text>
                                <Text color={textColor}>You didn't enter BORROW AMOUNT!!!</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* ==================Loader Modal====================== */}
            <Modal isOpen={modal4.isOpen} onClose={modal4.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Sit tight! Getting the tokens for you! .....</ModalHeader>

                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor={textColor}
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================Loader Modal ends================= */}
        </>
    )
}

export default FirstComponent
