import { MdAccountBalance, MdApi, MdAssuredWorkload, MdDataExploration, MdExtensionOff, MdOutlineSegment, MdOutlineLinearScale, MdLanguage, MdSupervisorAccount } from 'react-icons/md';
import image from "../../images/Pago-Coin.png"
export const links = [
  {
    label: 'Dashboard',
    href: '/dashboard',
    //icon: image,
    icon: MdAccountBalance,
    children: [
      {
        label: 'Dashboard',
        description: 'Read our documentation and FAQs, or get in touch.',
        href: '/dashboard',
        //icon: <MdLanguage />,
      },
    ],
  },
  // {
  //   label: 'Swap',
  //   children: [
  //     {
  //       label: 'Swap',
  //       description: 'Read our documentation and FAQs, or get in touch.',
  //       href: '/Swap',
  //       // icon: <MdAccessAlarm />,
  //     },
  //     {
  //       label: 'Pools',
  //       description: 'Read our documentation and FAQs, or get in touch.',
  //       href: '/pools',
  //       // icon: <MdAccessAlarm />,
  //     },
  //     // {
  //     //   label: 'Liquidity',
  //     //   description: 'Discover and join your local Sketch community.',
  //     //   href: '/liquidity',
  //     //   // icon: <MdWeb />,
  //     // },
  //     {
  //       label: 'Liquidity',
  //       description: 'Discover and join your local Sketch community.',
  //       href: '/liquidity',
  //       // icon: <MdWeb />,
  //     },
  //     {
  //       label: 'Withdraw',
  //       description: 'Discover and join your local Sketch community.',
  //       href: '/withdraw',
  //       // icon: <MdWeb />,
  //     },
  //   ],
  // },
  {
    label: 'Liquidity',
    icon: MdApi,
    children: [

      {
        label: 'Deposit',
        description: 'Discover and join your local Sketch community.',
        href: '/deposit',
        // icon: <MdWeb />,
      },

      {
        label: 'Withdraw',
        description: 'Discover and join your local Sketch community.',
        href: '/withdraw',
        // icon: <MdWeb />,
      },
    ],
  },

  {
    label: 'Lending',
    icon: MdAssuredWorkload,
    children: [

      {
        label: 'Borrow ',
        description: 'Discover and join your local Sketch community.',
        href: '/borrow',
        // icon: <MdWeb />,
      },

      {
        label: 'Repay',
        description: 'Discover and join your local Sketch community.',
        href: '/repay',
        // icon: <MdWeb />,
      },

      {
        label: 'My Borrows',
        description: 'All my loans.',
        href: '/mynfts',
        // icon: <MdWeb />,
      },
      {
        label: 'Auctions',
        description: 'All my loans.',
        href: '/all-auction',
        // icon: <MdWeb />,
      },
      {
        label: 'Health Factor',
        description: 'Discover and join your local Sketch community.',
        href: '/health',
        // icon: <MdWeb />,
      },



    ],
  },
  {
    label: 'Buy NFT',
    icon: MdDataExploration,
    children: [
      {
        label: 'Marketplace ',
        description: 'Read our documentation and FAQs, or get in touch.',
        href: '/marketplace',
        // icon: <MdAccessAlarm />,
      },
      {
        label: 'Mint ',
        description: 'Discover and join your local Sketch community.',
        href: '/mint',
        // icon: <MdWeb />,
      },


    ],
  },
  {
    label: 'Info',
    icon: MdOutlineLinearScale,
    children: [
      {
        label: 'Docs',
        description: 'Discover and join your local Sketch community.',
        href: 'https://docs.galapago.app',
        newTab: true,
        // icon: <MdWeb />,
      },

    ],
    /*
    children: [
      {
        label: 'NFT Airdrops',
        description: 'Read our documentation and FAQs, or get in touch.',
        href: '#',
        // icon: <MdAccessAlarm />,
      },
      {
        label: 'Governance ',
        description: 'Join the community.',
        href: '#',
        // icon: <MdWeb />,
      },
      {
        label: 'Docs',
        description: 'Discover and join your local Sketch community.',
        href: 'https://docs.galapago.app',
        // icon: <MdWeb />,
      },

    ],

     */
  },

];


export const profile = [

  {
    icon: MdSupervisorAccount,
    children: [

      {
        label: 'Profile',
        description: 'Discover and join your local Sketch community.',
        href: '/profile',
        // icon: <MdWeb />,
      },

      {
        label: 'My Collection',
        description: 'Discover and join your local Sketch community.',
        href: '/mycollection',
        // icon: <MdWeb />,
      },
    ],
  },
];



export const languages = [
  {
    icon: MdLanguage,
    align: 'center',
    children: [
      {
        value: 'en',
        label: 'English',
        href: '#',
      },
      {
        value: 'sp',
        label: 'Spanish',
        href: '#',
      },
      {
        value: 'ch',
        label: 'Chinese',
        href: '#',
      },
    ],
  },
];

