import { Box, Container, HStack, useColorModeValue, VStack, Text, Image, Progress, Divider, Radio, Checkbox, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Alert, AlertIcon, useDisclosure, ModalCloseButton, Center, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pagoCoin from '../components/images/pago-head.png';
import pago from '../components/images/Pago-Coin.png';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    Timestamp,
    where,
    query,
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import axios from 'axios';
import algosdk, { algosToMicroalgos, bigIntToBytes, bytesToBigInt, decodeAddress, getApplicationAddress, microalgosToAlgos } from 'algosdk';
import MyAlgoConnect from "@randlabs/myalgo-connect";
import { useNavigate } from 'react-router-dom';
import { PoolAmountAction } from '../components/redux/actions';
import { rates } from '../components/UtilizationRate/UtilizationRateData';
import { API_URL } from '../url';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { peraWallet } from "./WalletNew";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify"
import user from "../hooks/User";
const nftWithdrawRef = collection(db, "withdraw");
const borrowAppId = parseInt(process.env.REACT_APP_BORROW_APP_ID);
const animationVariants = {
    hidden: {
        opacity: 0,
        scale: 0,
        rotate: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
    exit: {
        opacity: 0,
        scale: 0,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
};


function NewWithDraw(props) {
    const navigate = useNavigate();
    const [val, setVal] = useState();
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const appIdFromRedux = useSelector(state => state.token.borrow_app_id);
    const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const walletAddress = useSelector(state => state.token.walletConnectAddress);
    const walletAmount = useSelector(state => state.token.walletConnectBalance);
    const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
    const deposit_rate = useSelector(state => state.token.deposit_rate);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const textColor = useColorModeValue("gray.600", "whiteAlpha.900");
    const [allNfts, setAllNfts] = useState([]);
    const nftCollectionRef = collection(db, "nfts");
    const nftBorrowRef = collection(db, "borrow");
    const [checkbox, setCheckBox] = useState(true);
    const [checkBoxItem, setCheckBoxItem] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal1 = useDisclosure();
    const modal2 = useDisclosure();
    const modal3 = useDisclosure();
    const [item1, setItem1] = useState(false);
    const [item2, setItem2] = useState(false);
    const [item3, setItem3] = useState(false);
    const [item4, setItem4] = useState(false);
    const [item5, setItem5] = useState(false);
    const [data, setData] = useState([]);
    const [borrowAmount, setAmount] = useState(0);
    const nftDepositRef = collection(db, "deposit");
    const [totalDeposit, setDeposit] = useState();
    const [totalBorrow, setBorrow] = useState();
    const [poolAmountNew, setPoolAmountNew] = useState();
    const borrowInfoRef = collection(db, "borrow-info");
    const [borrowInfo, setBorrowInfo] = useState();
    const [checkAmountStatus, setCheckAmountStatus] = useState(false);
    //const [depositAccAmount, setDepositAccAmount] = useState();
    const dispatch = useDispatch();
    const poolAmount = useSelector(state => state.token.pool_amount);
    const [depositAmount, setDepositAmount] = useState(0);

    const depositRef = collection(db, "deposit");
    const depositSummaryRef = collection(db, "depositSummary");
    const [allDeposit, setAllDeposit] = useState();
    const [depositSum, setAllDepositSum] = useState();
    const [allWithdrawal, setAllWithdrawal] = useState();
    const [allInfo, setAllInfo] = useState();
    const [withdrawAMount, setWithdrawAMount] = useState();
    const [newWithdrawAMount, setNewWithdrawAMount] = useState(0);
    const [withdrawStatus, setWithdrawStatus] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);
    const app_ID = useSelector(state => state.token.borrow_app_id);

    const marks = {

        0: <strong>0%</strong>,
        25: '25%',
        50: '50%',
        75: '75%',
        100: {
            style: {
                color: 'green',
            },
            label: <strong>100%</strong>,
        },
    };

    let total_Diposit_Amount = 0;
    let totalDipositAmount = 0;
    let totalBorrowAmount = 0;

    useEffect(() => {
        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef);
            setAllNfts(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
        };

        getNfts();

        pool_amount_func();

        let amount = 0;
        const utilisationRate = async () => {

            // let totalDipositAmount = 0;
            const depositData = await getDocs(nftDepositRef);
            await depositData.docs.map((doc) => {
                totalDipositAmount += parseFloat(doc.data().amount);
                // setDeposit(totalDipositAmount);
            })
            setDeposit(totalDipositAmount);
            // let totalBorrowAmount = 0;
            const borrowData = await getDocs(nftBorrowRef);
            await borrowData.docs.map((doc) => {
                amount += parseFloat(doc.data().amount);
                // setBorrow(totalBorrowAmount);
            })
            setBorrow(amount);
            let utilisationRateVar = totalBorrowAmount / totalDipositAmount;

        };

        utilisationRate();

        const getBorrowInfoFromFirebase = async () => {
            const data = await getDocs(borrowInfoRef);
            setBorrowInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowInfoFromFirebase();

        const getDepositInfo = async () => {
            const data = await getDocs(depositRef);
            setAllDeposit(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getDepositInfo();

        const getWithdrawInfo = async () => {
            const data = await getDocs(nftWithdrawRef);
            setAllWithdrawal(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getWithdrawInfo();

        const getAllBorrowInfo = async () => {

            const q2 = query(collection(db, "deposit"), where("account", "==", algoAdd));
            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                let docData = doc.data();
                await setAllInfo({ ...allInfo, allInfo: docData });
            });

        };
        getAllBorrowInfo()

        const getDepositSUmmary = async () => {
            const data = await getDocs(depositSummaryRef);
            setAllDepositSum(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getDepositSUmmary();

        const depositAmount = async () => {
            const data = await getDocs(nftDepositRef);
            data.docs.map((doc) => {
                total_Diposit_Amount += parseFloat(doc.data().amount)
                setDeposit(total_Diposit_Amount);
            })
        };

        depositAmount();

    }, []);
    /*
    console.log(borrowInfo);
    console.log(allInfo);
    console.log(allDeposit);
    console.log(depositSum);

     */

    // calculating initial index start

    const depositSummaryInfo = depositSum?.find((item) => item.type === "deposit")
    //console.log(depositSummaryInfo);

    const alpha = (depositSummaryInfo?.poolAPR / depositSummaryInfo?.timeVal) * 100;
    //console.log(alpha);
    const time = depositSummaryInfo?.changeOfTime;
    const nowTime = Date.now()
    const nowTimeSecons = Math.round(nowTime / 1000);
    let timeInterval = nowTimeSecons - Number(time?.seconds);
    //console.log(timeInterval * alpha)

    const index = depositSummaryInfo?.initialIndex * (1 + (timeInterval * alpha))
    /*
    //console.log(index);
    //console.log(total_Diposit_Amount);
    console.log(totalDeposit);
    console.log(depositSummaryInfo?.initialIndex);

    // console.log(index / depositSummaryInfo?.initialIndex);

     */
    const depositWithInterest = totalDeposit * (index / depositSummaryInfo?.initialIndex)
    //console.log(depositWithInterest);
    // calculating initial index ends
    const ownDeposit = allDeposit?.filter((item) => item.account === algoAdd)
    //console.log(ownDeposit);
    let totalDepositOwn = 0;
    const dep = ownDeposit?.map((item) => {
        totalDepositOwn += Number(item.amount)
    })
    //console.log(totalDepositOwn);

    // get user's deposit and withdrawal history from Firestore:
    function getUserBalance() {

        const userDeposit = allDeposit?.filter((item) => item.account === algoAdd);
        const userWithdrawal = allWithdrawal?.filter((item) => item.account === algoAdd);

        let userTotalDeposit = 0;
        const userDep = userDeposit?.map((item) => {
            userTotalDeposit += Number(item.amount)
        })

        let userTotalWithdrawal = 0;
        const userWithdra = userWithdrawal?.map((item) => {
            userTotalWithdrawal += Number(item.amount)
        })
        const remainingBalance = userTotalDeposit - userTotalWithdrawal;
        return remainingBalance;
    }

    const userBalance = getUserBalance();
    //finding deposit rate
    const borrow_rate_new = borrowInfo?.map((item) => {
        if ((totalBorrow / totalDeposit) < item.Uoptimal) {
            return item.R0 + ((totalBorrow / totalDeposit) / item.Uoptimal) * item.Rslope1
        } else {
            return item.R0 + item.Rslope1 + ((totalBorrow / totalDeposit) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
        }
    })

    //console.log(borrow_rate_new?.[0]);

    const deposit_rate_new = (totalBorrow / totalDeposit) * borrow_rate_new?.[0] * (1 - 0.3);

    const valueRate = rates.filter((data) => data.utilization === Math.round(totalBorrow / totalDeposit));
    const pool_amount_func = async () => {
        const algodClient = new algosdk.Algodv2("", 'https://node.testnet.algoexplorerapi.io', '');

        const escrowAddress = await getApplicationAddress(borrowAppId);

        let accountInfo = await algodClient.accountInformation(escrowAddress).do();

        let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount);
        setPoolAmountNew(accountAmount)
        //dispatch(PoolAmountAction(accountAmount));
    }

    const userNFT = allNfts.filter((data) => data.owner_address === algoAdd);

    const handleWithdrawAmount = (e) => {
        // if (e.target.value > userBalance) {
        //     setCheckAmountStatus(true);
        //     setTimeout(() => {
        //         setCheckAmountStatus(false);
        //     }, 3000);
        // } else {
        //     setNewWithdrawAMount(e.target.value)
        // }


        if (e.target.value >= 0 <= userBalance) {
            const inputValue = e.target.value;
            if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= userBalance) {
                setNewWithdrawAMount(inputValue);
                const newSliderValue = (inputValue / algoBalance) * 100;
                setSliderValue(newSliderValue);
            } else if (inputValue < 0) {
                //console.log("erereoreor");
                toast.error("Invalid Amount")
            } else if (inputValue > algoBalance) {
                toast.error("Not enough balance")
            }
        }

    }

    function log(value) {
        setVal(value)
        const newAmount = userBalance * value / 100;
        setNewWithdrawAMount(newAmount.toFixed(2));
        setSliderValue(value);
    }

    const withdraw = async () => {

        try {
            const algodClient = new algosdk.Algodv2("", 'https://node.testnet.algoexplorerapi.io', '');
            const withdraw = new Uint8Array(Buffer.from('withdraw_algo', 'utf8'));
            let withdrawAmount = algosdk.algosToMicroalgos(newWithdrawAMount);
            withdrawAmount = bigIntToBytes(withdrawAmount, 8)
            const totalDepositWithInterest = bigIntToBytes(depositWithInterest, 8)
            const suggested_params = await algodClient.getTransactionParams().do();
            const txn2 = algosdk.makeApplicationCallTxnFromObject({
                from: algoAdd,
                appIndex: app_ID,
                onComplete: 0,
                appArgs: [withdraw, withdrawAmount, totalDepositWithInterest],
                suggestedParams: suggested_params,

            })

            const txns = [txn2];

            const groupID = algosdk.assignGroupID(txns)

            // const myAlgoConnect = new MyAlgoConnect();
            // const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));

            //peraWallet connection
            const singleTxnGroups = [{ txn: txn2, signers: [algoAdd] }];

            const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

            const response = await algodClient.sendRawTransaction([signedTxn[0]]).do();
            setWithdrawStatus(true);
            await addDoc(nftWithdrawRef, { amount: newWithdrawAMount, account: algoAdd, app_id: app_ID, time: Timestamp.now() });

            modal2.onOpen();
            setTimeout(() => {
                setWithdrawStatus(false);
            }, 6000);

            setTimeout(() => {
                modal2.onClose();
            }, 12000);
            setTimeout(() => {
                navigate("/dashboard")
            }, 4000);

        } catch (error) {
            toast.error("ERROR, in Withdraw.")
        }
    }

    return (
        <>
            <Box zIndex="100" top="0" position="fixed" width="100%">{walletConnectStatus ? <DesktopNav name={walletAddress} amount={walletAmount} /> : <DesktopNav name={algoAdd} amount={algoBalance} />}</Box>

            <Box minH="100vh" py="50px">

                <Box w="100%" mt="70px">
                    <Container maxW={'3xl'} borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" pb="20px">
                        <HStack alignItems="center" justifyContent="space-between" justifyItems="center" px="20px" py="30px">
                            <VStack align="left">
                                <Text fontSize="30px" fontWeight="600" mb="-5px">Withdraw</Text>
                                <HStack>
                                    <Text fontSize="18px" color={textColor}>Total Withdrawable amount :
                                    </Text>
                                    <Image src={pago} h="20px" w="20px" />
                                    {/* <Text fontSize="18px" color={textColor} fontWeight="700">{depositWithInterest ? depositWithInterest.toFixed(2) : totalDepositOwn}
                                    </Text> */}
                                    <Text fontSize="18px" color={textColor} fontWeight="700">{userBalance ? userBalance.toFixed(2) : "0"}
                                    </Text>
                                </HStack>

                            </VStack>

                            <Image src={pagoCoin} h="50px" w="50px" />
                        </HStack>

                        {/* <VStack spacing={7}>
                            <Progress hasStripe isAnimated value={25} w="100%" />
                            <Divider color="gray.500" p="1px" />
                        </VStack> */}
                        <HStack py="20px" width="100%" bgColor={bgColor} px="20px" my="20px" borderRadius="15px" justifyItems="center" justifyContent="space-between">
                            <VStack>
                                <Text fontSize="12px" color={textColor}>Utilization rate</Text>
                                {/* <Text fontSize="18px" color={textColor} fontWeight="700">{((totalBorrow / totalDeposit) * 100).toFixed(2)}
                                    %</Text> */}
                                <Text fontSize="18px" color={textColor} fontWeight="700">{((totalBorrow / poolAmountNew) * 100).toFixed(2)}
                                    %</Text>

                            </VStack>

                            <VStack>
                                <Text fontSize="12px" color={textColor}>Pool balance</Text>
                                <Text fontSize="18px" color={textColor} fontWeight="700" >{poolAmountNew ? poolAmountNew.toFixed(2) : 0}</Text>
                            </VStack>

                        </HStack>

                        <Box
                            maxW={'100%'}
                            w={'full'}
                            bg={useColorModeValue('white', 'gray.800')}
                            boxShadow={'2xl'}
                            rounded={'md'}
                            overflow={'hidden'}
                            h="230px"

                        >
                            {/* <VStack align="left" py="20px" px="20px">
                                <Text fontSize="16px" fontWeight="500">Withdraw Amount</Text>
                                <Text fontSize="45px" color="yellow.400" >{withdrawAMount ? withdrawAMount.toFixed(4) : 0}</Text>
                            </VStack> */}
                            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} borderRadius="15px">
                                <VStack w="100%" align="left">

                                    <Text fontSize={{ base: "14px", md: "14px" }} fontWeight="600" textAlign="left">Withdraw Amount</Text>
                                    <Input
                                        type="number"
                                        name='Amount'
                                        value={newWithdrawAMount}
                                        required={true}
                                        border="none"
                                        color="gray.500"
                                        bgColor={'gray.200'}
                                        placeholder='Please enter withdraw amount'
                                        py="30px"
                                        min="0"
                                        onChange={(e) => handleWithdrawAmount(e)}
                                    />
                                </VStack>

                            </HStack>

                            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="30px" width="100%" bgColor={bgColor} m="10px 0px 40px 0px" borderRadius="15px" pb="20px">
                                <Slider min={0} marks={marks} step={1} max={100} onChange={log} value={sliderValue} />
                            </HStack>
                        </Box>

                        <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                            <Text fontSize="16px" fontWeight="400" textAlign="center">Withdraw tokens immediately. </Text>

                        </HStack>

                        <Button width="100%" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} onClick={withdraw}>Withdraw</Button>

                        {checkAmountStatus ?
                            <Alert status='warning'>
                                <AlertIcon />
                                You can't insert more than {userBalance?.toFixed(2)} !!!
                            </Alert>
                            : ""
                        }

                    </Container>
                </Box>
            </Box>

            {/* ==================successful Withdrwa modal start=================== */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose} isCentered>
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent width="400px" height="350px">
                    <ModalCloseButton />
                    <ModalCloseButton />
                    <AnimatePresence>
                        {modal2.isOpen && (
                            <motion.ModalBody
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={animationVariants}
                                borderRadius="15px"
                                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            >
                                <Center>
                                    <VStack alignItems="center" justifyContent="space-between" py="20%">
                                        <Text color={textColor} textAlign="center" fontSize="30px"
                                            fontWeight="bold">Congratulations!!</Text>
                                        <Text color={textColor} textAlign="center" fontSize="25px" fontWeight="bold">You
                                            successfully made the WITHDRAW.</Text>
                                        <Text textAlign="center" fontSize="25px" fontWeight="bold" color={textColor}>Have fun.</Text>
                                    </VStack>
                                </Center>
                            </motion.ModalBody>
                        )}
                    </AnimatePresence>
                </ModalContent>
            </Modal>
            {/* ==================successful Withdraw modal ends=================== */}

            <Modal isOpen={modal3.isOpen} onClose={modal3.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Sorry!!</Text>
                                <Text color={textColor}>Please enter amount</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default NewWithDraw
