import { Box, Container, HStack, useColorModeValue, VStack, Text, Image, Progress, Divider, Radio, Checkbox, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Alert, AlertIcon, useDisclosure, ModalCloseButton, Center, Input, Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pagoCoin from '../components/images/pago-head.png';
import pago from '../components/images/Pago-Coin.png';
import MainComponent from '../components/repayComponent/MainComponent';
import Header from '../components/header/Header';

function Repay() {
    const textColor = useColorModeValue("gray.600", "whiteAlpha.500");

    return (
        <>
            <Header />
            <Box minH="100vh" py="50px">
                <Box w="100%" mt="70px">
                    <Container maxW={'3xl'} borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" pb="20px">
                        <HStack alignItems="center" justifyContent="space-between" justifyItems="center" px="20px" py="30px">
                            <VStack align="left">
                                <Text fontSize="30px" fontWeight="600" mb="-5px">Repay</Text>
                                <Text fontSize="18px" color={textColor}>Make a repayment to your loan
                                </Text>
                            </VStack>

                            <Image src={pagoCoin} h="50px" w="50px" />
                        </HStack>

                        <MainComponent />

                    </Container>
                </Box>
            </Box>
        </>
    )
}

export default Repay;
