import { Alert, AlertIcon, Box, Button, Container, HStack, Input, InputGroup, InputLeftElement, InputRightElement, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../header/Header';
import {
    collection,
    getDocs,
    addDoc
} from "firebase/firestore";
//import { db } from '../components/firebase/FirebaseConfig';
import { db } from '../firebase/FirebaseConfig';
import { supplyAmountAction } from '../redux/actions';
import algosdk, { bigIntToBytes, getApplicationAddress, decodeAddress, waitForConfirmation } from "algosdk";
import axios from 'axios';
import { API_URL } from '../../url';

function TokenForBorrow() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const allAssets = useSelector(state => state.token.assets_from_account_2);
    const address = useSelector(state => state.token.myAlgoAddress);
    const borrow_item = useSelector(state => state.token.borrow_item);
    const supplyRef = collection(db, "supply");

    const [amount, setAmount] = useState();
    const [info, setInfo] = useState();
    const [amountSection, setAmountSection] = useState(false);
    const [status, setStatus] = useState(false);
    const [exchange_Rate, setExchangeRate] = useState();
    const [submittedAmount, setSubmittedAmount] = useState(0);
    const [equivalentAlgo, setEquivalentAlgo] = useState(0);
    const dispatch = useDispatch();
    const [noUSDC, setNoUSDC] = useState(false);
    const [condition, setCondition] = useState(false);

    const func2 = (item) => {
        //console.log(item);
        setInfo(item);
        setAmountSection(!amountSection);
    }
    //console.log(info);


    //let exchange_Rate = 0;

    useEffect(() => {
        const exchangeRate = () => {

            // await axios('http://192.168.0.135:12000/swap/checkOptin?address=' + address)
            axios(`${API_URL}swap/assetPrice`)
                .then(response => {
                    if (response.status === 200) {

                        setExchangeRate(response?.data)

                    }

                })
                .catch(error => {


                })
        }
        exchangeRate();
    }, []);

    useEffect(() => {
        const hasUSDC = allAssets?.some((asset) => asset["asset-id"] === 10458941);
        setNoUSDC(!hasUSDC);

    }, [allAssets]);

    //console.log(exchange_Rate);
    const handleSupply = (e) => {
        e.preventDefault();
        setAmount(e.target.value)
        if (e.target.value < 0) {
            setCondition(true);
            setTimeout(() => {
                setCondition(false);
            }, 3000);
            setAmount("");
        } else {
            const total_supply_amount = (e.target.value * exchange_Rate?.usdc_price) / exchange_Rate?.algo;

            dispatch(supplyAmountAction({
                supply_amount: e.target.value,
                //supply_amount: (amount * exchange_Rate?.usdc_price) / exchange_Rate?.algo,
                supply_amount_from_user: e.target.value,
                supply_Asset: "USDC"
            }));

            // if (amount) {
            //     console.log("enter an amount");
            //     addDoc(supplyRef, { owner_address: address, supplyAmount: amount, assetId: "10458941", borrow_item_asset_id: borrow_item[0]?.assetID });
            //     setStatus(true);
            //     onClose();
            //     setTimeout(() => {
            //         setStatus(false);
            //     }, 5000);
            //     setAmount("");
            //     setSubmittedAmount(amount);
            //     setEquivalentAlgo(total_supply_amount);// Update the submitted amount
            // }
        }

    }

    return (
        <>
            {/* <Header /> */}
            <Box minH="50vh" py="50px">
                <Text
                    fontSize="2xl"
                    fontWeight="bold"
                    textAlign="center"
                    mb="1rem"
                >
                    Increase your loan amount by adding tokens as collateral.
                </Text>
                {/* first component start from here  */}
                {noUSDC && (
                    <Container maxW="md" textAlign="center" mt="4" mb="4">
                        <Text fontSize="lg" fontWeight="bold" color="red.500">
                            You don't have any eligible tokens to use as additional collateral.
                            You can skip and go to the Next step.
                        </Text>
                    </Container>
                )}

                <Box w="100%" mt="20px">
                    <Container maxW={'4xl'} borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" py="20px" >

                        {!noUSDC && (
                            <TableContainer>
                                <Table variant='simple'>
                                    <Thead>
                                        <Tr>
                                            <Th>Assets</Th>
                                            <Th>Your Balance</Th>
                                            <Th>Deposit Rate</Th>
                                            <Th>Borrow Rate</Th>
                                            {/* <Th>Liquidity</Th> */}

                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {allAssets?.map((item, key) => (
                                            item["asset-id"] == "10458941" ?
                                                <Tr key={key}>
                                                    <Td>{item["asset-id"] == "10458941" ? "USDC" : item["asset-id"]}</Td>
                                                    <Td>{algosdk.microalgosToAlgos(item?.amount)}</Td>
                                                    <Td>0.0039%</Td>
                                                    <Td>2%</Td>
                                                    {/* <Td>1</Td> */}
                                                    <Td><Button onClick={() => { onOpen(); func2(item); }}>Supply</Button></Td>
                                                    {/* <Td><Button>Borrow</Button></Td> */}

                                                </Tr>
                                                : ""
                                        ))}


                                    </Tbody>

                                </Table>

                            </TableContainer>
                        )}

                        {amountSection ?
                            <TableContainer style={{ marginTop: "70px" }}>
                                <HStack style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Text as='b' fontSize='sm'>Amount</Text>
                                    <Text fontSize='sm'>Available: {algosdk.microalgosToAlgos(info?.amount)} USDC</Text>
                                </HStack>
                                {/* <form onSubmit={handleSupply}> */}
                                <InputGroup my="15px">
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='gray.300'
                                        fontSize='1.2em'
                                    />
                                    {/* <Input type="number" placeholder='Enter amount' value={amount} onChange={(e) => setAmount(e.target.value)} /> */}
                                    <Input type="number" placeholder='Enter amount' value={amount} onChange={(e) => handleSupply(e)} />

                                </InputGroup>
                                {/* <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} type="submit" w="100%" py="15px"> Submit</Button> */}
                                {/* </form> */}
                                {condition ? <Alert status='warning' >
                                    <AlertIcon />
                                    You need to insert a positive number !!
                                </Alert> : ""}
                                <Box w="100%" mt="20px">
                                    {
                                        status ?
                                            <>
                                                <Alert status='success' >
                                                    <AlertIcon />
                                                    Successful! Please click on the Next button to go to the step!
                                                </Alert>
                                                <Box mt="20px" borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" py="20px" px="10px" bg="white">
                                                    <Text fontSize="lg" fontWeight="bold">Submitted Amount:</Text>
                                                    <Text fontSize="md">{submittedAmount} USDC</Text>
                                                    <Text fontSize="lg" fontWeight="bold">Equivalent ALGO Amount:</Text>
                                                    <Text fontSize="md">{equivalentAlgo.toFixed(6)} ALGO</Text>
                                                </Box>
                                            </>
                                            :
                                            null
                                    }
                                </Box>

                            </TableContainer>
                            : ""
                        }


                    </Container>

                </Box>
            </Box>
        </>

    )
}

export default TokenForBorrow
