import { Box, Container, HStack, useColorModeValue, VStack, Text, Image, Progress, Divider, Radio, Checkbox, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Alert, AlertIcon, useDisclosure, ModalCloseButton, Center, Input, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pagoCoin from '../components/images/pago-head.png';
import pago from '../components/images/Pago-Coin.png';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    Timestamp,
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import axios from 'axios';
import algosdk, { algosToMicroalgos, bigIntToBytes, bytesToBigInt, decodeAddress, getApplicationAddress, microalgosToAlgos } from 'algosdk';
import MyAlgoConnect from "@randlabs/myalgo-connect";
import { useNavigate } from 'react-router-dom';
import { getAllAssetsAction, myAlgoWalletInfo, PoolAmountAction } from '../components/redux/actions';
import { rates } from '../components/UtilizationRate/UtilizationRateData';
import { API_URL } from '../url';
import { peraWallet } from "./WalletNew";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { toast } from "react-toastify"

const borrowAppId = parseInt(process.env.REACT_APP_BORROW_APP_ID);


const animationVariants = {
    hidden: {
        opacity: 0,
        scale: 0,
        rotate: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
    exit: {
        opacity: 0,
        scale: 0,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
};

function Deposit(props) {
    const navigate = useNavigate();
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const appIdFromRedux = useSelector(state => state.token.borrow_app_id);
    //const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const walletAddress = useSelector(state => state.token.walletConnectAddress);
    const walletAmount = useSelector(state => state.token.walletConnectBalance);
    const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
    const deposit_rate = useSelector(state => state.token.deposit_rate);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const textColor = useColorModeValue("gray.600", "whiteAlpha.900");
    const [allNfts, setAllNfts] = useState([]);
    const nftCollectionRef = collection(db, "nfts");
    const nftBorrowRef = collection(db, "borrow");
    const [checkbox, setCheckBox] = useState(true);
    const [checkBoxItem, setCheckBoxItem] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal1 = useDisclosure();
    const modal2 = useDisclosure();
    const modal3 = useDisclosure();
    const modal4 = useDisclosure();
    const [item1, setItem1] = useState(false);
    const [item2, setItem2] = useState(false);
    const [item3, setItem3] = useState(false);
    const [item4, setItem4] = useState(false);
    const [item5, setItem5] = useState(false);
    const [data, setData] = useState([]);
    const [borrowAmount, setAmount] = useState(0);
    const nftDepositRef = collection(db, "deposit");
    const [totalDeposit, setDeposit] = useState();
    const [totalBorrow, setBorrow] = useState();
    const [poolAmountNew, setPoolAmountNew] = useState();
    const borrowInfoRef = collection(db, "borrow-info");
    const [borrowInfo, setBorrowInfo] = useState()
    const [checkAmount, setCheckAmount] = useState(false)
    //const [depositAccAmount, setDepositAccAmount] = useState();
    const dispatch = useDispatch();
    const poolAmount = useSelector(state => state.token.pool_amount);
    const [sliderValue, setSliderValue] = useState(0);
    let totalBorrowAmount = 0;
    let totalDipositAmount = 0;

    const marks = {

        0: <strong>0%</strong>,
        25: '25%',
        50: '50%',
        75: '75%',
        100: {
            style: {
                color: 'green',
            },
            label: <strong>100%</strong>,
        },
    };

    const handleChange = (event) => {
        // const inputValue = parseFloat(event.target.value);
        //     if (!isNaN(inputValue)) {
        //         setAmount(inputValue);
        //         const newSliderValue = (inputValue / algoBalance) * 100;
        //         setSliderValue(newSliderValue);
        //     }
        // console.log(event.target.value);



        // if (event.target.value >= 0 <= algoBalance) {
        //     const inputValue = event.target.value;
        //     if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= algoBalance) {
        //         setAmount(inputValue);
        //         const newSliderValue = (inputValue / algoBalance) * 100;
        //         setSliderValue(newSliderValue);
        //     } else {
        //         //console.log("erereoreor");
        //         toast.error("insufficient Balance")
        //     }
        // }

        if (event.target.value >= 0 <= algoBalance) {
            const inputValue = event.target.value;
            if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= algoBalance) {
                setAmount(inputValue);
                const newSliderValue = (inputValue / algoBalance) * 100;
                setSliderValue(newSliderValue);
            } else if (inputValue < 0) {
                //console.log("erereoreor");
                toast.error("Invalid Amount")
            } else if (inputValue > algoBalance) {
                toast.error("Not enough balance")
            }
        }

    }

    function log(value) {
        const newAmount = algoBalance * value / 100;
        setAmount(newAmount.toFixed(2));
        setSliderValue(value);
    }

    useEffect(() => {
        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef);
            setAllNfts(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
        };

        getNfts();

        pool_amount_func();
        let amount = 0;
        const utilisationRate = async () => {


            const depositData = await getDocs(nftDepositRef);
            await depositData.docs.map((doc) => {
                totalDipositAmount += parseFloat(doc.data().amount)
                setDeposit(totalDipositAmount);
            })


            const borrowData = await getDocs(nftBorrowRef);
            await borrowData.docs.map((doc) => {
                amount += parseFloat(doc.data().amount)
                setBorrow(amount);

            })

            let utilisationRateVar = totalBorrowAmount / totalDipositAmount;

        };

        utilisationRate();

        const getBorrowInfoFromFirebase = async () => {
            const data = await getDocs(borrowInfoRef);
            setBorrowInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowInfoFromFirebase();



    }, []);

    //finding deposit rate
    const borrow_rate_new = borrowInfo?.map((item) => {
        if ((totalBorrow / poolAmountNew) < item.Uoptimal) {
            return item.R0 + ((totalBorrow / poolAmountNew) / item.Uoptimal) * item.Rslope1
        } else {
            return item.R0 + item.Rslope1 + ((totalBorrow / poolAmountNew) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
        }

    })
    const deposit_rate_new = (totalBorrow / poolAmountNew) * borrow_rate_new?.[0] * (1 - 0.3);

    const valueRate = rates.filter((data) => data.utilization === Math.round(totalBorrow / totalDeposit));
    const pool_amount_func = async () => {
        const algodClient = new algosdk.Algodv2("", 'https://node.testnet.algoexplorerapi.io', '');

        const escrowAddress = await getApplicationAddress(borrowAppId);

        let accountInfo = await algodClient.accountInformation(escrowAddress).do();

        let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount);
        setPoolAmountNew(accountAmount)
        //dispatch(PoolAmountAction(accountAmount));
    }

    const userNFT = allNfts.filter((data) => data.owner_address === algoAdd);

    async function getUserBalance(address) {
        try {
            const response = await axios(`${API_URL}swap/checkOptin?address=` + address);

            if (response.status === 200) {
                const optinResult = response.data;

                dispatch(myAlgoWalletInfo({
                    algoAddress: address,
                    algoBalance: response.data.clientInfo.amount / 1000000
                }));

            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    }
    const handleDeposit = () => {

        const appId = appIdFromRedux;
        const assetID = 108232239;
        if (Number(borrowAmount) > Number(algoBalance)) {
            setCheckAmount(true);
            setTimeout(() => {
                setCheckAmount(false);
                setAmount("")
            }, 3000);
        }
        else if (borrowAmount <= 0) {
            modal3.onOpen();
        } else {
            //onOpen();
            modal4.onOpen();
            setTimeout(() => {
                fund_escrow_for_deposit(appId, assetID)
                // assetOptin(appId, assetID)
            }, 3000);
        }

    }
    const assetOptin = async (appId, assetid) => {

        const algodClient = new algosdk.Algodv2("", 'https://node.testnet.algoexplorerapi.io', '');
        const params = await algodClient.getTransactionParams().do();
        var note = new Uint8Array([10]);

        let sender = algoAdd;
        let recipient = sender;
        let revocationTarget = undefined;
        let closeRemainderTo = undefined;
        let amount = 0;
        let assetID = assetid;
        // signing and sending "txn" allows sender to begin accepting asset specified by creator and index
        let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(
            sender,
            recipient,
            closeRemainderTo,
            revocationTarget,
            amount,
            note,
            assetID,
            params);

        const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

        onClose();
        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
        const response = await algodClient.sendRawTransaction(signedTxn).do()
        setItem3(true);
        setTimeout(() => {
            fund_escrow_for_deposit(appId, assetid)
        }, 2000);

    }

    const fund_escrow_for_deposit = async (appId, assetID) => {

        try {
            const algodClient = new algosdk.Algodv2("", 'https://node.testnet.algoexplorerapi.io', '');
            const suggested_params = await algodClient.getTransactionParams().do();

            const escrowAddress = await getApplicationAddress(appId);

            const diposit = new Uint8Array(Buffer.from('diposit', 'utf8'));
            const fund = bigIntToBytes(borrowAmount, 8)


            const txn1 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                from: algoAdd,
                suggestedParams: suggested_params,
                to: escrowAddress,
                amount: (borrowAmount * 1000000)
            })

            const txns = [txn1];

            const groupID = algosdk.assignGroupID(txns);
            //onClose();
            modal4.onClose();

            //peraWallet connection
            const singleTxnGroups = [{ txn: txn1, signers: [algoAdd] }];

            const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

            const response = await algodClient.sendRawTransaction([signedTxn[0]]).do();

            setItem1(true);
            //onOpen();
            modal4.onOpen();
            setTimeout(() => {
                //setPopUp(false);
                make_borrow_offer_for_deposit(appId, assetID);
            }, 4000);

        } catch (error) {
            toast.error("ERROR, in Fund Escrow.")
        }
    }

    const escrow_lsg_address = async () => {

        let escrow_fund_program_compiled = undefined
        let escrow_address = undefined

        await axios(`${API_URL}swap/depositLogicSig`)
            .then(response => {

                if (response.status === 200) {

                    let data = response.data;

                    escrow_fund_program_compiled = data.escrow_fund_program_compiled
                    escrow_address = data.escrow_address

                }

            })
            .catch(error => {
                //console.error("Error fatching data", error);
                toast.error("Error fatching data")

            })

        const algodClient = new algosdk.Algodv2("", 'https://node.testnet.algoexplorerapi.io', '');

        const escrowlcompiledProgram = await algodClient.compile(escrow_fund_program_compiled).do();

        const escrowProgramBytes = new Uint8Array(
            Buffer.from(escrowlcompiledProgram.result, 'base64')
        );


        return escrowProgramBytes;

    }

    const make_borrow_offer_for_deposit = async (appId, assetID) => {

        try {
            const algodClient = new algosdk.Algodv2("", 'https://node.testnet.algoexplorerapi.io', '');
            const suggested_params = await algodClient.getTransactionParams().do();

            const escrowAddress = await getApplicationAddress(appId);

            const diposit = new Uint8Array(Buffer.from('diposit', 'utf8'));
            let accountInfo = await algodClient.accountInformation(escrowAddress).do();


            let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount)

            // dispatch(PoolAmountAction(accountAmount));

            accountAmount = parseInt(accountAmount)

            const totalAmount = bigIntToBytes(accountAmount, 8)

            const depositAmount = bigIntToBytes(borrowAmount, 8)
            const pool_apr = bigIntToBytes(10, 8);

            let address = getApplicationAddress(appId);

            let end_Time = new Date().getTime();

            end_Time = bigIntToBytes((end_Time), 8);
            const txn2 = algosdk.makeApplicationCallTxnFromObject({
                from: algoAdd,
                appIndex: appId,
                onComplete: 0,
                appArgs: [diposit, totalAmount, depositAmount, pool_apr, end_Time],
                suggestedParams: suggested_params,
                // foreignAssets:[92772865],
                foreignAssets: [assetID],
                accounts: [escrowAddress]


            })
            const logicSigInfo = await escrow_lsg_address();
            let lsig = new algosdk.LogicSigAccount(logicSigInfo)


            const txns = [txn2];

            const groupID = algosdk.assignGroupID(txns)


            // const myAlgoConnect = new MyAlgoConnect();

            // const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));

            //peraWallet connection
            const singleTxnGroups = [{ txn: txn2, signers: [algoAdd] }];

            //onClose();
            modal4.onClose();
            const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);


            const response = await algodClient.sendRawTransaction([signedTxn[0]]).do();


            let applicationInfoResponse = await algodClient.accountApplicationInformation("RRO7OHROIWSOA7YXJBACWXOTCG4BWX3XLNSJLTJUSPRI5KHP3JXUTDM63Q", appId).do();
            let depositIndex = 0


            for (let i = 0; i < applicationInfoResponse['created-app']['global-state'].length; i++) {

                if (applicationInfoResponse['created-app']['global-state'][i].key == "REVQT1NJVF9JTlRfSU5ERVg=") {
                    depositIndex = applicationInfoResponse['created-app']['global-state'][i].value.uint

                }

            }


            depositIndex = depositIndex / 100000000

            let pago_amount = ((borrowAmount) / depositIndex)

            let micro_pago_amount = pago_amount * 1000000


            // const txn3 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
            //     from: lsig.address(),
            //     suggestedParams: {
            //         ...suggested_params
            //     },
            //     to: algoAdd,
            //     amount: parseInt(micro_pago_amount),
            //     assetIndex: 108232239,
            //     revocationTarget: "XQUXRUK2XUNJDT22J5BKYUADZHRWE5K5R5M23SNYINHPUYXCE42V3OO27I",
            //     foreignAssets: [108232239]

            // })

            // let signedTxn1 = algosdk.signLogicSigTransactionObject(txn3, lsig);

            // const response1 = await algodClient.sendRawTransaction(signedTxn1.blob).do();
            // setItem2(true);

            await addDoc(nftDepositRef, { amount: borrowAmount, account: algoAdd, app_id: appId, time: Timestamp.now() });
            // After a successful deposit, update the user wallet balance
            await getUserBalance(algoAdd);

            // setTimeout(() => {
            //     onClose();
            // }, 12000);
            modal2.onOpen();
            setTimeout(() => {
                modal2.onClose();
            }, 5000);
            setTimeout(() => {
                navigate("/dashboard");
            }, 4000);

        } catch (error) {
            toast.error("ERROR, in Make Borrow Offer.")
        }


    }

    return (
        <>
            <Box zIndex="100" top="0" position="fixed" width="100%">{walletConnectStatus ? <DesktopNav name={walletAddress} amount={walletAmount} /> : <DesktopNav name={algoAdd} amount={algoBalance} />}</Box>

            <Box minH="100vh" py="50px">
                {/* first component start from here  */}

                <Box w="100%" mt="70px">
                    <Container maxW={'3xl'} borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" pb="20px">
                        <HStack alignItems="center" justifyContent="space-between" justifyItems="center" px="20px" py="30px">
                            <VStack align="left">
                                <Text fontSize="30px" fontWeight="600" mb="-5px">Deposit</Text>
                                <HStack>
                                    <Text fontSize="18px" color={textColor}>Available in wallet :
                                    </Text>
                                    <Image src={pago} h="20px" w="20px" />
                                    <Text fontSize="18px" color={textColor} fontWeight="700">{algoBalance.toFixed(2)}
                                    </Text>
                                </HStack>

                            </VStack>

                            <Image src={pagoCoin} h="50px" w="50px" />
                        </HStack>

                        {/* <VStack spacing={7}>
                            <Progress hasStripe isAnimated value={25} w="100%" />
                            <Divider color="gray.500" p="1px" />
                        </VStack> */}
                        <HStack py="20px" width="100%" bgColor={bgColor} px="20px" my="20px" borderRadius="15px" justifyItems="center" justifyContent="space-between">
                            <VStack>
                                <Text fontSize="12px" color={textColor}>Utilization rate</Text>
                                {/* <Text fontSize="18px" color={textColor} fontWeight="700">{((totalBorrow / totalDeposit) * 100).toFixed(2)}
                                    %</Text> */}
                                <Text fontSize="18px" color={textColor} fontWeight="700">{((totalBorrow / poolAmountNew) * 100).toFixed(2)}
                                    %</Text>

                            </VStack>

                            <VStack>
                                <Text fontSize="12px" color={textColor}>Pool balance</Text>
                                <Text fontSize="18px" color={textColor} fontWeight="700" >{poolAmountNew ? poolAmountNew.toFixed(2) : 0}</Text>
                            </VStack>

                            <VStack>
                                <Text fontSize="12px" color={textColor}>Deposit APR</Text>
                                <Text fontSize="18px" color={textColor} fontWeight="700">{(deposit_rate_new * 100).toFixed(2)}%</Text>


                            </VStack>
                        </HStack>


                        <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                            <VStack w="100%" align="left">
                                <Text fontSize="16px" fontWeight="600">Amount</Text>
                                <Input
                                    type="number"
                                    name='Amount'
                                    //value={borrowAmount.toFixed(2)}
                                    value={borrowAmount}
                                    required={true}
                                    border="none"
                                    color="gray.500"
                                    bgColor={'gray.200'}
                                    py="30px"
                                    placeholder='Please enter the deposit amount'
                                    onChange={handleChange}
                                />
                            </VStack>
                            {/* <VStack w="40%" align="center">
                                <Text fontSize="16px" fontWeight="600">Total available borrow</Text>
                                <HStack>
                                    <Image src={pago} w="25px" h="25px" />
                                    <Text>{algoBalance}</Text>
                                </HStack>
                            </VStack> */}

                        </HStack>

                        <HStack justifyContent="space-between" justifyItems="center" px="20px" py="30px" width="100%" bgColor={bgColor} m="10px 0px 40px 0px" borderRadius="15px" pb="20px">
                            <Slider min={0} marks={marks} step={1} max={100} onChange={log} value={sliderValue} />
                        </HStack>

                        <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                            <Text fontSize="16px" fontWeight="400" textAlign="center">Enter the amount of tokens you'd like to deposit. </Text>

                        </HStack>

                        <Button width="100%" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} style={{ marginBottom: "10px" }} onClick={handleDeposit}>Deposit</Button>

                        {checkAmount ? <Alert status='warning'>
                            <AlertIcon />
                            Not enough funds in wallet
                        </Alert> : ""}


                    </Container>
                </Box>
            </Box>

            {/* ===================Modal for deposit functionality====================== */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Deposit Action</ModalHeader>
                    <ModalBody py="30px">
                        <VStack>
                            {item1 ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Fund Escrow
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Fund Escrow
                                </Alert>
                            }
                            <Divider />
                            {item2 ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Make Deposit
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Make Deposit
                                </Alert>
                            }

                        </VStack>
                    </ModalBody>
                    <VStack>

                    </VStack>

                </ModalContent>
            </Modal>
            {/*========================== Modal for borrow functionality ends==================*/}

            {/* ==================successful Deposit modal start=================== */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose} isCentered>
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent width="400px" height="350px">
                    <ModalCloseButton />
                    <AnimatePresence>
                        {modal2.isOpen && (
                            <motion.ModalBody
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={animationVariants}
                                borderRadius="15px"
                                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            >
                                <Center>
                                    <VStack alignItems="center" justifyContent="space-between" py="20%">
                                        <Text color={textColor} textAlign="center" fontSize="30px"
                                            fontWeight="bold">Congratulations!!</Text>
                                        <Text color={textColor} textAlign="center" fontSize="25px" fontWeight="bold">You
                                            successfully made DEPOSIT.</Text>
                                        <Text textAlign="center" fontSize="25px" fontWeight="bold" color={textColor}>Have fun.</Text>
                                    </VStack>
                                </Center>
                            </motion.ModalBody>
                        )}
                    </AnimatePresence>
                </ModalContent>
            </Modal>
            {/* ==================successful Deposit modal ends=================== */}

            <Modal isOpen={modal3.isOpen} onClose={modal3.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Sorry!!</Text>
                                <Text color={textColor}>Please enter amount</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* ==================Loader Modal====================== */}
            <Modal isOpen={modal4.isOpen} onClose={modal4.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>In progress.....</ModalHeader>
                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================Loader Modal ends================= */}
        </>
    )
}

export default Deposit
