import { Box, Checkbox, HStack, Text, Image, useColorModeValue, Radio, Input, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query,
    where,
} from "firebase/firestore";

import { db } from '../firebase/FirebaseConfig';
import pago from '../images/Pago-Coin.png';
import { useDispatch, useSelector } from 'react-redux';
import { NFTcheckBoxAction, NFTrepayItemAction, RepayCheckboxAction, RepayItemAction } from '../redux/actions';

import { MdCheckCircleOutline, MdCheckCircle } from 'react-icons/md';


function FirstSlideForRepay({ setSelectedNFTs, setShowAlert, setIsFirstStepNextButtonDisabled }) {
    const [allNfts, setAllNfts] = useState([]);
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const [checkbox, setCheckBox] = useState(true);
    const [checkBoxItem, setCheckBoxItem] = useState([]);
    //const checkbox = useSelector((state) => state.token.repay_checkbox);
    const [repayItem, setRepayItem] = useState();
    const dispatch = useDispatch();
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");

    //let borrow_nft = [];
    useEffect(() => {
        const getBorrowedNfts = async () => {

            const q2 = query(collection(db, "borrow"), where("account", "==", algoAdd));
            //console.log("========hello, i am here========")
            const data2 = await getDocs(q2);
            //console.log("inside nft details")
            //console.log(data2);
            setAllNfts(data2.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.account })));

        };

        getBorrowedNfts();

    }, []);

    const [RepayData, setRepayData] = useState([]);

    useEffect(() => {
        if (allNfts.length > 0) {
            const repay = allNfts.filter((data) => data.repay_remaining_amount > 0);
            setRepayData(repay);
        } else {
            setRepayData([]);
        }
    }, [allNfts]);

    // console.log("==========", allNfts);
    //console.log("=============RepayData", RepayData);



    const [selectElement, setSelectElement] = useState();

    const handleClick = (id) => {
        setSelectElement(id)
    }



    const [item, setItem] = useState([]);
    const [markItem, setMark] = useState(false);
    const [selectedID, setID] = React.useState('');
    const handleCheckBox = (checkItem) => {
        setMark(true);

        // Case 1 : The user checks the box
        if (checkItem) {
            setItem(checkItem)
            setSelectedNFTs((prevSelectedNFTs) => {
                const updatedSelectedNFTs = [checkItem];
                setIsFirstStepNextButtonDisabled(false);
                return updatedSelectedNFTs;
            });
            setShowAlert(false);
            dispatch(RepayItemAction(checkItem));
            setRepayItem(checkItem);
            dispatch(RepayCheckboxAction(true));

        }

        // else {
        //     setItem(item.filter((e) => e != checkItem))
        //     setSelectedNFTs((prevSelectedNFTs) => {
        //         const updatedSelectedNFTs = prevSelectedNFTs.filter((nftId) => nftId !== checkItem);
        //         setIsFirstStepNextButtonDisabled(true);
        //         return updatedSelectedNFTs;
        //     });
        //     dispatch(RepayItemAction(''));
        //     dispatch(RepayCheckboxAction(false));
        //     setRepayItem(null);
        // }

    }

    // const handleCheckBox = (e, checkItem) => {

    //     //console.log(e.target.value);
    //     //console.log(item);
    //     // console.log(item);
    //     // Destructuring
    //     const { checked } = e.target;
    //     //console.log(`${value} is ${checked}`);

    //     // Case 1 : The user checks the box
    //     if (checked) {
    //         setItem([...item, checkItem])
    //         setSelectedNFTs((prevSelectedNFTs) => {
    //             const updatedSelectedNFTs = [...prevSelectedNFTs, checkItem];
    //             setIsFirstStepNextButtonDisabled(false);
    //             return updatedSelectedNFTs;
    //         });
    //         setShowAlert(false);
    //         dispatch(RepayItemAction(checkItem));
    //         setRepayItem(checkItem);
    //         dispatch(RepayCheckboxAction(true));

    //     } else {
    //         setItem(item.filter((e) => e != checkItem))
    //         setSelectedNFTs((prevSelectedNFTs) => {
    //             const updatedSelectedNFTs = prevSelectedNFTs.filter((nftId) => nftId !== checkItem);
    //             setIsFirstStepNextButtonDisabled(true);
    //             return updatedSelectedNFTs;
    //         });
    //         dispatch(RepayItemAction(''));
    //         dispatch(RepayCheckboxAction(false));
    //         setRepayItem(null);
    //     }

    // }
    //console.log(RepayData);
    return (
        <>
            <HStack py="20px" width="100%" bgColor={bgColor} px="20px" my="20px" borderRadius="15px">
                {/* <Radio colorScheme='red' value='1'>
                                <Text>NFT Name</Text>
                            </Radio> */}
                <Text fontSize="18px" fontWeight="400"> List of NFTs</Text>

            </HStack>

            {RepayData ? RepayData.map((item) => (
                <>
                    <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%"
                        bgColor={bgColor} my="20px" borderRadius="15px" key={item.app_id}>
                        <HStack>
                            {/* <Checkbox
                                colorScheme='green'
                                size='lg'
                                boxShadow="rgba(171,97,97, 0.1) 0px 7px 29px 0px"
                                value={markItem ? true : false}
                                defaultChecked={false}
                                onChange={(e) => handleCheckBox(e, item)}>
                            </Checkbox> */}
                            <></>
                            {/* <Radio
                                    id={item.assetIdForRepay}
                                    name="NFT"
                                    checked={item.assetIdForRepay === selectedID}
                                    //onChange={() => {setID(item.id}}
                                    onChange={() => setID(item.assetIdForRepay)}
                                /> */}
                            {/* <Button className='select-button' onClick={() => handleClick(item.assetIdForRepay)}>{selectElement === item.assetIdForRepay ? 'Selected ✓' : 'Select'}</Button> */}
                            <Box className='select-button' onClick={() => {
                                handleClick(item.assetID[0]); handleCheckBox(item)
                            }}>{selectElement === item.assetID[0] ? <MdCheckCircle size="20px" color='green' /> : <MdCheckCircleOutline size="20px" color='gray' />}</Box>


                            {item?.borrowItemImages ? item?.borrowItemImages.slice(0, 3).map((image) => (
                                <Image src={image} width="80px" height="80px" objectFit="cover"
                                    borderRadius="50%" />
                            )) :
                                <Image src={item.image} width="80px" height="80px" objectFit="cover"
                                    borderRadius="50%" />
                            }

                            <Text fontSize="18px" fontWeight="600">{item.name}</Text>
                        </HStack>
                        <HStack>
                            <HStack><Image src={pago} w="25px" h="25px" /></HStack>

                            <Text>{item.repay_remaining_amount > 0 ? Number(item.repay_remaining_amount).toFixed(2) : item.amount}</Text>
                        </HStack>
                    </HStack>

                </>
            )) : ""}

            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                <Text fontSize="16px" fontWeight="600">Total repay amount</Text>
                <HStack>
                    <Image src={pago} w="25px" h="25px" />
                    <Text>{repayItem ? Number(repayItem.repay_remaining_amount).toFixed(2) : 0.00}</Text>

                </HStack>
            </HStack>

            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                <Text fontSize="16px" fontWeight="400" textAlign="center">Choose at least one NFT loan which you want to repay. </Text>

            </HStack>
        </>
    )
}

export default FirstSlideForRepay
